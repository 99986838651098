import charactersApi from '@/api/characters';
import { openModal } from '@/components/BaseModal';
import { stateBase } from '@/helpers/store';
import { ICharacter, ICharacterCreationResponse, IImageDataInAlbum, ICharacterQuotaResponse, ICharacterCreationChoices, IDeleteCharacterResponse, IDeleteImageResponse, DEFAULT_CREATOR_USERNAME } from '@/models/characters';
import { CharacterSortTags, CharacterSortType } from '@/models/characters';
import { IImageGenQuotaResponse } from '@/models/image';
import { IAudioMessageQuotaResponse, IUnlockImageResponse } from '@/models/chat';
import { ICharacterAlbums } from '@/types/album';
import { IChatDetails, IChatMessage, IRecentChat, IChatMessageQuotaResponse, IChatStreamChunk, IChatStreamFinalResponse } from '@/models/chat';
import { IChatQuotaConfig, IChatsHistory, IFetchechedHistory } from '@/types/chat';
import { Modals } from '@/types/modals';
import { StateBase } from '@/types/store';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { default_avatar } from '@/assets/remote-assets';
import { persist } from 'zustand/middleware';
import { useUserStore } from './user';
import { useImageStore } from './image';
import { getWelcomeMessageId, setWelcomeMessageId } from '@/helpers/localStorage';
import posthog from 'posthog-js';
import { isImageURLExpired } from '@/lib/utils';
import { isChatStreamingEnabled } from '@/utils/env';

export interface CharactersState extends StateBase {
  filterTagsSelected: string[];
  sortTagSelected: string;
  characters: ICharacter[];
  characterChatProfiles: Record<number, number | null>;
  sendingMessage: boolean;
  streamingMessage: boolean;
  regenerating: boolean;
  resetingChat: boolean;
  deletingChat: boolean;
  loadingMore: boolean;
  fetchedHistories: IFetchechedHistory[];
  chatHistory: IChatsHistory;
  characterAlbums: ICharacterAlbums;
  characterNewImages: Record<number, number>;
  recentChats: IRecentChat[];
  recentChatsLoading: boolean;

  getCharacterIdByUniqueCode: (uniqueCode: string) => number | null;
  setFilterTagsSelected: (tags: string[]) => void;
  setSortTagSelected: (tag: string) => void;
  checkBalance: (gem_cost?: number) => boolean;
  getActiveChars: () => void;
  resetUserCharacters: () => void;
  getUserRecentChats: () => void;
  getChatHistory: (characterId: number, lastMessageId?: number) => void;
  pruneExpiredChatHistory: (characterId: number) => void;
  sendMessage: (
    characterId: number,
    message: string,
    imageRequest: boolean,
    onQuotaHit: (config: IChatQuotaConfig) => void,
    onSuccess: () => void,
  ) => void;
  sendMessageStream: (
    characterId: number,
    message: string,
    imageRequest: boolean,
    onQuotaHit: (config: IChatQuotaConfig) => void,
    onSuccess: () => void,
    isStreamingFallback?: boolean
  ) => void;
  unlockMessages: (quotaResponse: IChatMessageQuotaResponse, characterId: number) => Promise<boolean>;
  regenerateLastMessage: (characterId: number, onQuotaHit: (config: IChatQuotaConfig) => void, user_input?: string) => void;
  regenerateGreeting: (characterId: number, user_input?: string, input_type?: string) => void;
  continueLastMessage: (characterId: number) => void;
  continueLastMessageStream: (characterId: number) => void;
  regenerateLastMessageStream: (characterId: number, onQuotaHit: (config: IChatQuotaConfig) => void, user_input?: string) => void;
  generateImageForLastMessage: (characterId: number) => void;
  getById: (id: number) => ICharacter | undefined;
  getCharacterById: (id: number) => Promise<ICharacter | null>;
  resetChat: (id: number, onQuotaHit: (config: IChatQuotaConfig) => void, onSuccess: () => void, user_input?: string, input_type?: string) => void;
  deleteChat: (id: number, onSuccess: () => void) => void;
  unlockImage: (message: IChatMessage, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => Promise<boolean>;
  deleteImages: (characterId: number, imageIds: number[]) => Promise<IDeleteImageResponse | null>;
  updateMessage: (messageId: number, characterId: number, fields: Partial<IChatMessage>) => void;
  updateAlbumImage: (characterId: number, updatedImageData: IImageDataInAlbum) => void;
  getCharacterAlbums: (id: number) => void;
  getFanImages: (characterId: number, lastImageId: number, limit: number) => Promise<IImageDataInAlbum[]>;
  unlockAlbumImage: (imageData: IImageDataInAlbum, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => Promise<IImageDataInAlbum|null>;
  generateAudio: (messageId: number, characterId: number, messageText: string, isAuthenticated: boolean, onPaywallHit: (config: IAudioMessageQuotaResponse) => void) => Promise<AudioGenerationResult>;
  unlockAudioMessages: (quotaResponse: IAudioMessageQuotaResponse) => Promise<boolean>;
  createCharacter: (choices: Record<string, any>, onPaywallHit: (config: ICharacterQuotaResponse) => void, variant?: string | null) => Promise<ICharacterCreationResponse | null>;
  renameCharacter: (characterId: number, name: string) => Promise<ICharacter | null>;
  deleteCharacter: (characterId: number) => Promise<IDeleteCharacterResponse | null>;
  unlockCharacters: (quotaResponse: ICharacterQuotaResponse) => Promise<boolean>;
  unlockImageGen: (quotaResponse: IImageGenQuotaResponse) => Promise<boolean>;
  updateGemBalance: (res: any) => Promise<void>;
  getTotalPendingImages: (characterId: number) => number;
  getCharacterNewImages: (characterId: number) => number;
  clearCharacterNewImages: (characterId: number) => void;
  setCharacterActiveChatProfile: (characterId: number, chatProfileId: number) => Promise<boolean>;
}

export const charactersStorePersistKey = 'characters-store';

function mergeHistories(next: IChatDetails, prev?: IChatDetails) {
  if (!prev) {
    return next;
  }

  const prevHistory: IChatMessage[] = prev.history;
  const resultHistory: IChatMessage[] = [...prevHistory];

  next.history.reverse().forEach(message => {
    let alreadyExist = resultHistory.some(m => m.id === message.id);
    if (!alreadyExist) {
      // This is to deal with recently sent messages might not have message id yet
      alreadyExist = resultHistory.some(m => m.text === message.text && Math.abs((m.time_sent ?? 0) - (message.time_sent ?? 0)) < 2);
    }

    if (!alreadyExist) {
      resultHistory.unshift(message);
    }
  });

  return {
    ...next,
    history: resultHistory,
  };
}

function onNotEnoughBalance() {
  showToast("You don't have enough gems - get more!", ToastType.ERROR);
  setTimeout(() => openModal(Modals.Storage), 1000);
}

type AudioGenerationResult = 
  | { success: true; audioBlob: Blob }
  | { success: false; reason: 'insufficient_gems' | 'audio_locked' | 'other_error' };

const initialFilterTags = {
  default: [],
  characterType: [],
} as const;

export const useCharactersStore = create<CharactersState>()(
  persist(
    (set, get) => ({
      ...stateBase,
      filterTagsSelected: [],
      sortTagSelected: CharacterSortType.POPULAR,
      characters: [],
      characterChatProfiles: {},
      fetchedHistories: [],
      recentChats: [],
      characterAlbums: {},
      characterNewImages: {},
      chatHistory: {},
      initialized: false,
      regenerating: false,
      sendingMessage: false,
      streamingMessage: false,
      resetingChat: false,
      deletingChat: false,
      loadingMore: false,
      recentChatsLoading: false,
      filterTags: initialFilterTags,

      initialize: () => {
        const { initialized, getActiveChars } = get();

        if (!initialized) {
          getActiveChars();
        }
      },
      getCharacterIdByUniqueCode: (uniqueCode: string): number | null => {
        const character = get().characters.find(c => c.unique_code === uniqueCode);
        return character ? character.id : null;
      },
      setFilterTagsSelected: (tags: string[]) => {
        set({ filterTagsSelected: tags });
      },
      setSortTagSelected: (tag: string) => {
        set({ sortTagSelected: tag });
      },
      checkBalance: (gem_cost?: number) => {
        const {
          userStats: { gem_balance },
        } = useUserStore.getState();

        const enoughBalance = gem_cost && gem_cost <= gem_balance;

        if (!enoughBalance) {
          onNotEnoughBalance();
          return false;
        }
        return true;
      },
      getActiveChars: async () => {
        set({ loading: true });
        const res = await charactersApi.getActiveChars();
        if (res instanceof AxiosError) {
          const errorMessage = res.response?.data?.detail || res.message;
          showToast(`Can\'t get active chars. ${errorMessage}`, ToastType.ERROR);
          set({ loading: false });
        } else {
          set({ characters: res, loading: false });
        }
      },
      resetUserCharacters: () => {
        set({ characters: get().characters.filter(char => !char.created_by_user) });
      },
      getChatHistory: async (characterId, lastMessageId) => {
        try {
          const welcomeMessageId = getWelcomeMessageId(characterId);

          // skip fetching history when reached end of the history
          if (welcomeMessageId && welcomeMessageId === lastMessageId) {
            return;
          }

          const { recentChats: chatPreviews } = get();
          const chatPreview = chatPreviews.find(ch => ch.character_id === characterId);

          // set history from preview for visual loading speed up
          set(state => {
            const result: Partial<CharactersState> = { loading: true, loadingMore: !!lastMessageId };

            if (!state.chatHistory[characterId] && !!chatPreview?.history.length) {
              result.chatHistory = {
                ...state.chatHistory,
                [characterId]: {
                  history: chatPreview.history,
                  character: {
                    id: chatPreview.character_id,
                    name: chatPreview.character_name,
                    description: '',
                    avatar_url: chatPreview.avatar_url,
                    created_at: new Date(),
                    profile: '',
                    subtitle: '',
                    created_by_user: false,
                    creator_username: DEFAULT_CREATOR_USERNAME,
                    is_public: true,
                    unique_code: '',
                    seo_slug: ''
                  },
                },
              };
            }

            return result;
          });

          const historyRes = await charactersApi.getChatDetails(characterId, lastMessageId);

          if (historyRes) {
            // reached end of the list, remember last message id for this char, to skip further calls
            if (historyRes.history.length === 0 && lastMessageId) {
              setWelcomeMessageId(characterId, lastMessageId);
            }

            set(state => {
              const fetchedHistories = [...state.fetchedHistories];
              const fetchedHistoryItem = fetchedHistories.find(fh => fh.id === historyRes.character.id);

              // save new
              if (!fetchedHistoryItem) {
                fetchedHistories.push({
                  id: historyRes.character.id,
                });
                // clear previously possible errors
              } else if (fetchedHistoryItem.error) {
                fetchedHistoryItem.error = undefined;
              }
  
              let updatedState: Partial<CharactersState>;
              if (!state.characters.some(c => c.id === characterId) && historyRes?.character) {
                updatedState = {
                  characters: [...state.characters, historyRes.character],
                  chatHistory: {
                    ...state.chatHistory,
                    [characterId]: mergeHistories(historyRes, state.chatHistory[characterId]),
                  },
                  fetchedHistories,
                };
              }
              else {
                updatedState = {
                  chatHistory: {
                    ...state.chatHistory,
                    [characterId]: mergeHistories(historyRes, state.chatHistory[characterId]),
                  },
                  fetchedHistories,
                };  
              }

              if (historyRes.active_chat_profile) {
                set({ characterChatProfiles: { ...state.characterChatProfiles, [characterId]: historyRes.active_chat_profile } });
              }
              else {
                set({ characterChatProfiles: { ...state.characterChatProfiles, [characterId]: null } });
              }
  
              return updatedState;
            });

            // Check if characterId is not in chatPreview
            if (!get().recentChats.some(chat => chat.character_id === characterId)) {
              // Call getUserRecentChats to update the recent chats
              get().getUserRecentChats();
          }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t get chat details. ${errorMessage}`, ToastType.ERROR);

          set(state => {
            const fetchedHistories = [...state.fetchedHistories];
            const fetchedHistoryItem = fetchedHistories.find(fh => fh.id === characterId);

            if (!fetchedHistoryItem) {
              fetchedHistories.push({
                id: characterId,
                error: errorMessage,
              });
            } else {
              fetchedHistoryItem.error = errorMessage;
            }

            return { fetchedHistories };
          });
        } finally {
          set({ loading: false, loadingMore: false });
        }
      },
      pruneExpiredChatHistory: (characterId: number) => {
        set(state => {
          const chatHistory = state.chatHistory[characterId];
          if (!chatHistory) return state;

          const latestExpiredIndex = chatHistory.history.findLastIndex(msg => isImageURLExpired(msg.image_data));
          if (latestExpiredIndex === -1) return state;
          const updatedHistory = chatHistory.history.slice(latestExpiredIndex + 1);
          return { chatHistory: { ...state.chatHistory, [characterId]: { ...chatHistory, history: updatedHistory } } };
        });
      },
      getUserRecentChats: async () => {
        set({ recentChatsLoading: true });
        const res = await charactersApi.getUserRecentChats();

        if (res instanceof AxiosError) {
          const message = res.response?.data.detail;
          showToast(message, ToastType.ERROR);
          set({ recentChatsLoading: false });
          return;
        }

        const recentChats = res;
        const { chatHistory } = get();
        const historyCopy = { ...chatHistory };

        recentChats.forEach(ch => {
          // For recent chats that don't have unique_code or seo_slug from the API,
          // try to get them from existing characters in the store
          if (!ch.unique_code || !ch.seo_slug) {
            const existingCharacter = get().characters.find(c => c.id === ch.character_id);
            if (existingCharacter?.unique_code && existingCharacter?.seo_slug) {
              ch.unique_code = existingCharacter.unique_code;
              ch.seo_slug = existingCharacter.seo_slug;
            }
          }
          
          if (!historyCopy[ch.character_id]) {
            historyCopy[ch.character_id] = {
              character: {
                id: ch.character_id,
                name: ch.character_name,
                description: '',
                avatar_url: ch.avatar_url || default_avatar,
                created_at: new Date(),
                profile: '',
                subtitle: '',
                created_by_user: false,
                creator_username: DEFAULT_CREATOR_USERNAME,
                is_public: true,
                unique_code: ch.unique_code,
                seo_slug: ch.seo_slug
              },
              history: ch.history || [],
            };
          }
        });

        // chatHistory prepopulates chats by last 10 messages from getUserRecentChats
        set({ recentChats, chatHistory: historyCopy });
        set({ recentChatsLoading: false });
      },
      sendMessage: async (characterId, text, imageRequest, onQuotaHit, onSuccess) => {
        function removeLastMessage() {
          set(state => {
            const chatDetails = state.chatHistory[characterId];
            const history = [...chatDetails.history];
            history.pop();

            return {
              chatHistory: {
                ...state.chatHistory,
                [characterId]: {
                  ...chatDetails,
                  history,
                },
              },
            };
          });
        }

        function addMessageToHistory(message: IChatMessage) {
          set(state => {
            const currentHistory = state.chatHistory[characterId];
            currentHistory?.history.push(message);

            return state;
          });
        }

        function getUpdatedRecentChatsByLastInteraction(message: IChatMessage): IRecentChat[] {
          return [...get().recentChats].map(item => {
            if (item.character_id === characterId) {
              item.last_interaction_time = new Date();
              item.msg_preview = message.text;
              item.history = [...item.history.slice(-9), message];
              
              // Make sure the unique_code and seo_slug are present in the item
              if (!item.unique_code || !item.seo_slug) {
                const character = get().characters.find(c => c.id === characterId);
                if (character?.unique_code && character?.seo_slug) {
                  item.unique_code = character.unique_code;
                  item.seo_slug = character.seo_slug;
                }
              }
            }
            return item;
          });
        }

        try {
          addMessageToHistory({
            id: new Date().getTime(),
            time_sent: new Date().getTime() / 1000,
            sender: 'user',
            text,
            image_request: imageRequest,
          });
          set({ sendingMessage: true });
          const res = imageRequest ? await charactersApi.requestImage(characterId, text) : await charactersApi.sendMessageToChar(characterId, text);

          // Handle errors
          if (res instanceof AxiosError) {
            removeLastMessage();
            const message = res.response?.data.detail;
            if (message) {
              showToast(message, ToastType.ERROR);
            }
            posthog?.capture('send_message_failed', {'character_id': characterId, 'reason': message, 'image_request': imageRequest});
            return;
          }

          posthog?.capture('send_message_success', {'character_id': characterId, 'image_request': imageRequest});
          const answer = res[0];

          if (answer) {
            // Handle qouta hit
            if ('message_locked' in answer) {
              onQuotaHit({ ...answer, originalText: text, imageRequest: imageRequest });
              removeLastMessage();
            } else {
              // Handle success case
              addMessageToHistory({
                sender: 'character',
                ...answer,
              });
              const current = get().recentChats.find(ch => ch.character_id === characterId);
              if (!current) {
                get().getUserRecentChats();
              } else {
                const newMessage: IChatMessage = {
                  sender: 'character',
                  ...answer,
                };
                set({ recentChats: getUpdatedRecentChatsByLastInteraction(newMessage) });
              }

              setTimeout(onSuccess, 500);
            }
          }
        } catch (error) {
          set({ error });
        } finally {
          set({ sendingMessage: false });
        }
      },
      sendMessageStream: async (
        characterId: number, 
        text: string, 
        imageRequest: boolean, 
        onQuotaHit: (config: IChatQuotaConfig) => void, 
        onSuccess: () => void,
        isStreamingFallback?: boolean
      ) => {
        // Check if streaming is disabled via environment variable
        const enableStreaming = isChatStreamingEnabled();
        
        // If streaming is disabled, use the regular sendMessage
        if (!enableStreaming) {
          return get().sendMessage(characterId, text, imageRequest, onQuotaHit, onSuccess);
        }

        function removeLastMessage() {
          set(state => {
            const chatDetails = state.chatHistory[characterId];
            const history = [...chatDetails.history];
            history.pop();

            return {
              chatHistory: {
                ...state.chatHistory,
                [characterId]: {
                  ...chatDetails,
                  history,
                },
              },
            };
          });
        }

        function addMessageToHistory(message: IChatMessage) {
          set(state => {
            const currentHistory = state.chatHistory[characterId];
            currentHistory?.history.push(message);

            return state;
          });
        }

        function getUpdatedRecentChatsByLastInteraction(message: IChatMessage): IRecentChat[] {
          return [...get().recentChats].map(item => {
            if (item.character_id === characterId) {
              item.last_interaction_time = new Date();
              item.msg_preview = message.text;
              item.history = [...item.history.slice(-9), message];
              
              // Make sure the unique_code and seo_slug are present in the item
              if (!item.unique_code || !item.seo_slug) {
                const character = get().characters.find(c => c.id === characterId);
                if (character?.unique_code && character?.seo_slug) {
                  item.unique_code = character.unique_code;
                  item.seo_slug = character.seo_slug;
                }
              }
            }
            return item;
          });
        }

        try {
          // Add user message to history
          addMessageToHistory({
            id: new Date().getTime(),
            time_sent: new Date().getTime() / 1000,
            sender: 'user',
            text,
            image_request: imageRequest,
          });
          
          // Set sendingMessage to true, but don't set streamingMessage yet
          // We'll set streamingMessage to true only when we receive the first token
          set({ sendingMessage: true });
          
          // For image requests, use the non-streaming endpoint
          if (imageRequest) {
            const res = await charactersApi.requestImage(characterId, text);
            
            // Handle errors
            if (res instanceof AxiosError) {
              removeLastMessage();
              const message = res.response?.data.detail;
              if (message) {
                showToast(message, ToastType.ERROR);
              }
              posthog?.capture('send_message_failed', {'character_id': characterId, 'reason': message, 'image_request': imageRequest});
              return;
            }
            
            posthog?.capture('send_message_success', {'character_id': characterId, 'image_request': imageRequest});
            const answer = res[0];
            
            if (answer) {
              // Handle quota hit
              if ('message_locked' in answer) {
                onQuotaHit({ ...answer, originalText: text, imageRequest: imageRequest });
                removeLastMessage();
              } else {
                // Handle success case
                addMessageToHistory({
                  sender: 'character',
                  ...answer,
                });
                const current = get().recentChats.find(ch => ch.character_id === characterId);
                if (!current) {
                  get().getUserRecentChats();
                } else {
                  const newMessage: IChatMessage = {
                    sender: 'character',
                    ...answer,
                  };
                  set({ recentChats: getUpdatedRecentChatsByLastInteraction(newMessage) });
                }
                
                setTimeout(onSuccess, 500);
              }
            }
            
            set({ sendingMessage: false });
            return;
          }
          
          // For text messages, use the streaming endpoint
          try {
            const streamResponse = await charactersApi.sendMessageToCharStream(characterId, text);

            posthog?.capture('send_message_success', {'character_id': characterId, 'image_request': imageRequest});

            // Process the streaming response
            try {
              const reader = streamResponse.body?.getReader();
              if (!reader) {
                throw new Error('No reader available');
              }

              // Create a new message for the character response
              const messageId = new Date().getTime() + 1;
              addMessageToHistory({
                id: messageId,
                time_sent: new Date().getTime() / 1000,
                sender: 'character',
                text: '', // Start with empty text, will be filled as chunks arrive
              });

              let decoder = new TextDecoder();
              let buffer = '';
              let completeText = '';
              let isFirstChunk = true;

              while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                buffer += decoder.decode(value, { stream: true });
                
                // Process complete lines
                let lines = buffer.split('\n');
                buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer
                
                for (const line of lines) {
                  if (!line.trim()) continue;
                  
                  try {
                    const data = JSON.parse(line);
                    
                    if (data.text_chunk) {
                      // If this is the first chunk, set streamingMessage to true
                      if (isFirstChunk) {
                        set({ streamingMessage: true });
                        isFirstChunk = false;
                      }
                      
                      // Handle streaming chunk
                      completeText += data.text_chunk;
                      
                      // Update the message with the accumulated text
                      set(state => {
                        const chatDetails = state.chatHistory[characterId];
                        const history = [...chatDetails.history];
                        const lastMessage = history[history.length - 1];
                        
                        if (lastMessage && lastMessage.sender === 'character') {
                          lastMessage.text = completeText;
                        }
                        
                        return {
                          chatHistory: {
                            ...state.chatHistory,
                            [characterId]: {
                              ...chatDetails,
                              history,
                            },
                          },
                        };
                      });
                    } else {
                      // Handle final message with metadata
                      set(state => {
                        const chatDetails = state.chatHistory[characterId];
                        const history = [...chatDetails.history];
                        const lastMessage = history[history.length - 1];
                        
                        if (lastMessage && lastMessage.sender === 'character') {
                          // Update with final data
                          lastMessage.id = parseInt(data.id);
                          lastMessage.text = data.text;
                          lastMessage.time_sent = data.time_sent;
                          lastMessage.image_data = data.image_data;
                          lastMessage.image_locked = data.image_locked;
                          lastMessage.gem_cost = data.gem_cost;
                          lastMessage.image_id = data.image_id;
                          lastMessage.image_request_id = data.image_request_id;
                        }
                        
                        return {
                          chatHistory: {
                            ...state.chatHistory,
                            [characterId]: {
                              ...chatDetails,
                              history,
                            },
                          },
                        };
                      });
                      
                      // Update recent chats
                      const current = get().recentChats.find(ch => ch.character_id === characterId);
                      if (!current) {
                        get().getUserRecentChats();
                      } else {
                        const newMessage: IChatMessage = {
                          id: parseInt(data.id),
                          sender: 'character',
                          text: data.text,
                          time_sent: data.time_sent,
                          image_data: data.image_data,
                          image_locked: data.image_locked,
                          gem_cost: data.gem_cost,
                          image_id: data.image_id,
                        };
                        set({ recentChats: getUpdatedRecentChatsByLastInteraction(newMessage) });
                      }
                    }
                  } catch (e) {
                    console.error('Error parsing JSON:', e);
                  }
                }
              }
              
              setTimeout(onSuccess, 500);
            } catch (error) {
              console.error('Error processing stream:', error);
              set({ error });
              
              // If there's an error processing the stream, remove the partial message
              removeLastMessage();
              
              // Fallback to the regular sendMessage function if not already a fallback
              if (!isStreamingFallback) {
                console.log('Falling back to regular sendMessage due to stream processing error');
                get().sendMessage(characterId, text, false, onQuotaHit, onSuccess);
              } else {
                showToast('Failed to process message stream', ToastType.ERROR);
              }
              return;
            }
          } catch (error) {
            console.error('Error sending message:', error);
            
            // Fallback to the regular sendMessage if not already a fallback
            if (!isStreamingFallback) {
              console.log('Falling back to regular sendMessage due to streaming API error');
              get().sendMessage(characterId, text, false, onQuotaHit, onSuccess);
            } else {
              showToast('Failed to send message', ToastType.ERROR);
              removeLastMessage();
            }
            return;
          } finally {
            set({ sendingMessage: false, streamingMessage: false });
          }
        } catch (error) {
          console.error('Error in sendMessageStream:', error);
          set({ error });
          
          // Fallback to the regular sendMessage if not already a fallback
          if (!isStreamingFallback) {
            console.log('Falling back to regular sendMessage due to general error');
            get().sendMessage(characterId, text, false, onQuotaHit, onSuccess);
          } else {
            showToast('Failed to send message', ToastType.ERROR);
            removeLastMessage();
          }
          set({ sendingMessage: false, streamingMessage: false });
        }
      },
      unlockMessages: async (quotaResponse: IChatMessageQuotaResponse, characterId: number) => {
        const res = await charactersApi.unlockMessages(characterId);
        await get().updateGemBalance(res);
        const isError = res instanceof AxiosError;
        if (isError) {
          showToast(res.response?.data.detail || 'Unable to unlock', ToastType.ERROR);
          return false;
        }
        else {
          const success = !isError && res.unlock_successful;    
          if (!success) {
            showToast('Unable to unlock', ToastType.ERROR);
          }
          return success;
        }
      },
      regenerateLastMessage: async (id, onQuotaHit, user_input) => {
        let initialMessage: IChatMessage;

        set(state => {
          const { chatHistory } = state;
          const { history } = chatHistory[id];
          const lastMessage = history.findLast(m => m.sender === 'character');
          const lastUserMessage = history.findLast(m => m.sender === 'user');

          // clear last char message data
          if (lastMessage) {
            // save copy of the message we override in case of errors
            initialMessage = { ...lastMessage };
            lastMessage.image_data = undefined;
            lastMessage.text = '';

            // Update the last user message if user_input is provided
            if (user_input && lastUserMessage) {
              lastUserMessage.text = user_input;
            }
          }

          return { sendingMessage: true, regenerating: true, chatHistory };
        });

        // clear last char message data
        const res = await charactersApi.regenerateLastMessage(id, user_input);

        if (res instanceof AxiosError) {
          if (res.response?.data.detail) {
            showToast(res.response.data.detail, ToastType.ERROR);
          }

          // set original message data back
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[id];
            const lastMessage = history.findLast(m => m.sender === 'character');

            if (lastMessage) {
              Object.assign(lastMessage, initialMessage);
            }

            return { sendingMessage: false, regenerating: false };
          });
        } else {
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[id];
            const updatedMessage = history.find(m => m.id === initialMessage.id);

            if (updatedMessage) {
              Object.assign(updatedMessage, { ...res, text: res.text });
            }

            return { sendingMessage: false, regenerating: false, chatHistory };
          });
        }
      },
      regenerateGreeting: async (id, user_input, input_type) => {
        let initialMessage: IChatMessage;

        set(state => {
          const { chatHistory } = state;
          const { history } = chatHistory[id];
          const lastMessage = history.findLast(m => m.sender === 'character');

          // clear last char message data
          if (lastMessage) {
            // save copy of the message we override in case of errors
            initialMessage = { ...lastMessage };
            lastMessage.image_data = undefined;
            lastMessage.text = '';
          }

          return { sendingMessage: true, regenerating: true, chatHistory };
        });

        get().resetChat(id, () => {}, () => {}, user_input, input_type);
        set(state => {
          return { sendingMessage: false, regenerating: false };
        });
      },
      continueLastMessage: async (id) => {
        set({ sendingMessage: true });

        const res = await charactersApi.continueLastMessage(id);

        if (res instanceof AxiosError) {
          if (res.response?.data.detail) {
            showToast(res.response.data.detail, ToastType.ERROR);
          }

          set({ sendingMessage: false });
        } else {
          const newMessage : IChatMessage = {
            sender: 'character',
            ...res,
          }

          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[id];
            history.push(newMessage);

            return { sendingMessage: false, chatHistory };
          });
        }
      },
      continueLastMessageStream: async (characterId) => {
        // Check if streaming is disabled via environment variable
        const enableStreaming = isChatStreamingEnabled();
        
        // If streaming is disabled, use the regular continueLastMessage
        if (!enableStreaming) {
          return get().continueLastMessage(characterId);
        }

        set({ sendingMessage: true });

        try {
          const streamResponse = await charactersApi.continueLastMessageStream(characterId);
          
          try {
            const reader = streamResponse.body?.getReader();
            if (!reader) {
              throw new Error('No reader available');
            }

            // Get the last message to reference its text
            const lastMessage = get().chatHistory[characterId].history.findLast(m => m.sender === 'character');
            
            // Store the original text as a reference but don't modify it
            const originalText = lastMessage?.text || '';
            
            // Create a new message for the character response (continuation)
            const messageId = new Date().getTime();
            
            // Add a new message to the chat history with empty text initially
            set(state => {
              const chatDetails = state.chatHistory[characterId];
              const history = [...chatDetails.history];
              
              // Add new empty message for continuation
              history.push({
                id: messageId,
                time_sent: new Date().getTime() / 1000,
                sender: 'character',
                text: '', // Start with empty text, will be filled as chunks arrive
              });
                            
              return {
                chatHistory: {
                  ...state.chatHistory,
                  [characterId]: {
                    ...chatDetails,
                    history,
                  },
                },
              };
            });
            
            let decoder = new TextDecoder();
            let buffer = '';
            let completeText = ''; // Start with empty text for the new message
            let isFirstChunk = true;
            let receivedAnyChunks = false;

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              
              buffer += decoder.decode(value, { stream: true });
              
              // Process complete lines
              let lines = buffer.split('\n');
              buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer
              
              for (const line of lines) {
                if (!line.trim()) continue;
                
                try {
                  const data = JSON.parse(line);
                  
                  if (data.text_chunk) {
                    // If this is the first chunk, set streamingMessage to true
                    if (isFirstChunk) {
                      set({ streamingMessage: true });
                      isFirstChunk = false;
                    }
                    
                    // Mark that we've received at least one chunk
                    receivedAnyChunks = true;
                    
                    // Handle streaming chunk - append to the new message text
                    completeText += data.text_chunk;
                    
                    // Update the message with the accumulated text
                    set(state => {
                      const chatDetails = state.chatHistory[characterId];
                      const history = [...chatDetails.history];
                      const lastMessage = history[history.length - 1];
                      
                      if (lastMessage && lastMessage.sender === 'character') {
                        lastMessage.text = completeText;
                      }
                      
                      return {
                        chatHistory: {
                          ...state.chatHistory,
                          [characterId]: {
                            ...chatDetails,
                            history,
                          },
                        },
                      };
                    });
                  } else if (data.id) {
                    // This is the final message with full data
                    // Update the new message with the final response data
                    receivedAnyChunks = true;
                    
                    set(state => {
                      const chatDetails = state.chatHistory[characterId];
                      const history = [...chatDetails.history];
                      const lastMessage = history[history.length - 1];
                      
                      if (lastMessage && lastMessage.sender === 'character') {
                        // Update the message with the complete data from the final response
                        Object.assign(lastMessage, {
                          id: data.id,
                          text: data.text,
                          time_sent: data.time_sent,
                          image_data: data.image_data,
                          image_locked: data.image_locked,
                          gem_cost: data.gem_cost,
                          image_id: data.image_id,
                          generated_by_others: data.generated_by_others,
                          image_request_id: data.image_request_id
                        });
                      }
                      
                      return {
                        chatHistory: {
                          ...state.chatHistory,
                          [characterId]: {
                            ...chatDetails,
                            history,
                          },
                        },
                      };
                    });
                    
                    // Also update recent chats if needed
                    const lastMessage = get().chatHistory[characterId].history[get().chatHistory[characterId].history.length - 1];
                    if (lastMessage && lastMessage.sender === 'character') {
                      const current = get().recentChats.find(c => c.character_id === characterId);
                      if (!current) {
                        get().getUserRecentChats();
                      } else {
                        const updatedRecentChats = [...get().recentChats].map(item => {
                          if (item.character_id === characterId) {
                            item.last_interaction_time = new Date();
                            item.msg_preview = lastMessage.text;
                            item.history = [...item.history.slice(-9), lastMessage];
                            
                            // Make sure the unique_code and seo_slug are present in the item
                            if (!item.unique_code || !item.seo_slug) {
                              const character = get().characters.find(c => c.id === characterId);
                              if (character?.unique_code && character?.seo_slug) {
                                item.unique_code = character.unique_code;
                                item.seo_slug = character.seo_slug;
                              }
                            }
                          }
                          return item;
                        });
                        set({ recentChats: updatedRecentChats });
                      }
                    }
                  }
                } catch (e) {
                  console.error('Error parsing JSON:', e);
                }
              }
            }
            
            // If we didn't receive any chunks, remove the empty message and show an error
            if (!receivedAnyChunks) {
              
              // Remove the empty message we added
              set(state => {
                const chatDetails = state.chatHistory[characterId];
                const history = [...chatDetails.history];
                
                // Remove the last message if it's empty
                if (history.length > 0 && history[history.length - 1].text === '') {
                  history.pop();
                }
                
                return {
                  chatHistory: {
                    ...state.chatHistory,
                    [characterId]: {
                      ...chatDetails,
                      history,
                    },
                  },
                };
              });
              
              // Show an error message
              showToast('The character has nothing more to say. Try sending a msg instead.', ToastType.ERROR);
            }
            
          } catch (error) {
            console.error('Error processing stream:', error);
            set({ error });
            
            // Remove the empty message we added
            set(state => {
              const chatDetails = state.chatHistory[characterId];
              const history = [...chatDetails.history];
              
              // Remove the last message if it's empty
              if (history.length > 0 && history[history.length - 1].text === '') {
                history.pop();
              }
              
              return {
                chatHistory: {
                  ...state.chatHistory,
                  [characterId]: {
                    ...chatDetails,
                    history,
                  },
                },
              };
            });
            
            // Fallback to the regular continueLastMessage function
            console.log('Falling back to regular continueLastMessage due to stream processing error');
            get().continueLastMessage(characterId);
            return;
          }
        } catch (error) {
          console.error('Error in continueLastMessageStream:', error);
          set({ error });
          
          // Remove the empty message we added
          set(state => {
            const chatDetails = state.chatHistory[characterId];
            const history = [...chatDetails.history];
            
            // Remove the last message if it's empty
            if (history.length > 0 && history[history.length - 1].text === '') {
              history.pop();
            }
            
            return {
              chatHistory: {
                ...state.chatHistory,
                [characterId]: {
                  ...chatDetails,
                  history,
                },
              },
            };
          });
          
          // Fallback to the regular continueLastMessage function
          console.log('Falling back to regular continueLastMessage due to general error');
          get().continueLastMessage(characterId);
        } finally {
          set({ sendingMessage: false, streamingMessage: false });
        }
      },
      generateImageForLastMessage: async (characterId: number) => {
        set({ sendingMessage: true, regenerating: true});

        // clear last char message data
        const res = await charactersApi.generateImageForLastMessage(characterId);

        if (res instanceof AxiosError) {
          if (res.response?.data.detail) {
            showToast(res.response.data.detail, ToastType.ERROR);
          }
          set({ sendingMessage: false, regenerating: false });
        } else {
          const receviedMessage = { ...res };
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[characterId];
            const updatedMessage = history.find(m => m.id === receviedMessage.id);

            if (updatedMessage) {
              Object.assign(updatedMessage, { ...res, text: res.text });
            }

            return { sendingMessage: false, regenerating: false, chatHistory };
          });
        }
      },
      getById: id => get().characters.find(c => c.id === id),
      getCharacterById: async (id: number): Promise<ICharacter | null> => {
        // First check if the character is already in the store
        const character = get().characters.find(c => c.id === id);
        if (character) {
          return character;
        }
        
        // If not found in store, fetch from API
        try {
          set({ loading: true });
          const response = await charactersApi.getCharacterById(id);
          
          // Check if response is an error
          if (response instanceof AxiosError) {
            set({ loading: false, error: response });
            console.error('Error fetching character:', response);
            return null;
          }
          
          if (response) {
            // Ensure created_by_user and creator_username are properly set
            const processedResponse: ICharacter = {
              ...response,
              created_by_user: response.created_by_user || false,
              creator_username: response.creator_username || DEFAULT_CREATOR_USERNAME
            };
            
            // Add the character to the store if it's not already there
            const existingCharacters = get().characters;
            const characterExists = existingCharacters.some(c => c.id === processedResponse.id);
            
            if (!characterExists) {
              set({ characters: [...existingCharacters, processedResponse] });
            }
            
            set({ loading: false });
            return processedResponse;
          }

          set({ loading: false });
          return null;
        } catch (error) {
          console.error('Error fetching character by ID:', error);
          set({ loading: false, error: error as Error });
          return null;
        }
      },
      resetChat: async (id, onQuotaHit, onSuccess, user_input, input_type) => {
        try {
          set({ resetingChat: true });
          const res = await charactersApi.resetChat(id, user_input, input_type);

          if (!(res instanceof AxiosError)) {
            const answer = res[0];
            if (answer && 'message_locked' in answer) {
              // Handle qouta hit
              const text = answer.text || '';
              onQuotaHit({ ...answer, originalText: text, imageRequest: false });
            } 
            else {
              set(state => {
                const targetHistory = state.chatHistory[id];
                // clear existing history, keep only welcome message
                targetHistory.history = [];
                if (res && res[0] && 'id' in res[0]) {
                  const resetMessage : IChatMessage = {
                    ...res[0],
                    sender: 'character',
                  };
                  targetHistory.history.push(resetMessage);
                }
                return state;
              });
              get().getUserRecentChats();
              onSuccess();
            }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t reset chat. ${errorMessage}`, ToastType.ERROR);
        } finally {
          set({ resetingChat: false });
        }
      },
      deleteChat: async (id, onSuccess) => {
        try {
          set({ deletingChat: true });
          const res = await charactersApi.deleteChat(id);

          if (!(res instanceof AxiosError)) {
            set(state => ({ recentChats: state.recentChats.filter(chat => chat.character_id !== id) }));

            if (onSuccess) {
              onSuccess();
            }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t delete chat. ${errorMessage}`, ToastType.ERROR);
        } finally {
          set({ deletingChat: false });
        }
      },
      updateMessage: async (messageId, characterId, fields) => {
        set(state => {
          const { chatHistory } = state;
          const chatDetails = chatHistory[characterId];
          const messageIndex = chatDetails.history.findIndex(m => m.id === messageId);
          const message = chatDetails.history[messageIndex];
          const historyCopy = [...chatDetails.history];

          if (message && fields) {
            const updatedMessage: IChatMessage = {
              ...message,
              ...fields,
            };

            historyCopy.splice(messageIndex, 1, updatedMessage);
          }

          return {
            chatHistory: {
              ...chatHistory,
              [characterId]: {
                ...chatDetails,
                history: historyCopy,
              },
            },
          };
        });
      },
      unlockImage: async (message, characterId, onPaywallHit: (config: IUnlockImageResponse) => void) => {
        const { updateMessage } = get();

        updateMessage(message.id, characterId, { unlockPending: true });

        if (!message.image_id) {
          updateMessage(message.id, characterId, { unlockPending: false });
          return false;
        }

        const res = await charactersApi.unlockImage(characterId, message.image_id);
        await get().updateGemBalance(res);
        if (!(res instanceof AxiosError)) {
          if ('unlock_successful' in res && !res.unlock_successful) {
            if (res.subscription_required) {
              onPaywallHit(res as unknown as IUnlockImageResponse);
            }
            else {
              onNotEnoughBalance();
            }
            updateMessage(message.id, characterId, {
              unlockPending: false,
              image_locked: true,
            });
            return false;
          }

          updateMessage(message.id, characterId, {
            unlockPending: false,
            image_locked: !res.unlock_successful,
            image_data: res.image_data,
          });
        } else {
          updateMessage(message.id, characterId, { unlockPending: false });

          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }

        return true;
      },

      deleteImages: async (characterId: number, imageIds: number[]): Promise<IDeleteImageResponse | null> => {
        const res = await charactersApi.deleteImages(characterId, imageIds);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to delete', ToastType.ERROR);
          useImageStore.getState().setUpdateRequired(true);
          return null;
        }
        if (res && 'images_deleted' in res) {
          const returnedImages : IDeleteImageResponse = res as IDeleteImageResponse;
          useImageStore.getState().deleteImages(characterId, returnedImages.images_deleted);
          return returnedImages;
        }
        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },


      reset: async () => set({ chatHistory: {} }),
      getCharacterAlbums: async (id: number) => {
        const res = await charactersApi.getAlbums(id);
        if (res instanceof AxiosError) {
          showToast(res.message, ToastType.ERROR);
        } else {
          const prevPendingImages = get().getTotalPendingImages(id);
          set({ characterAlbums: { ...get().characterAlbums, [id]: res } });
          const newPendingImages = get().getTotalPendingImages(id);
          const difference = Math.max(prevPendingImages - newPendingImages, 0);
          set(state => ({
            characterNewImages: {
              ...state.characterNewImages,
              [id]: (state.characterNewImages[id] || 0) + difference
            }
          }));
        }
      },
      getFanImages: async (characterId: number, lastImageId: number, limit: number) => {
        const res = await charactersApi.getFanImages(characterId, lastImageId, limit);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to get fan images', ToastType.ERROR);
          return [];
        }
        return res;
      },
      updateAlbumImage: async (characterId: number, updatedImageData: IImageDataInAlbum) => {
        set(state => {
          const allCharacterAlbums = state.characterAlbums[characterId] || [];

          if (!Array.isArray(allCharacterAlbums)) {
            return state; // Return the current state without changes
          }

          const updatedAlbums = allCharacterAlbums.map(album => {
            const updatedImages = album.image_data?.map(imageData => {
              if (imageData.image_id === updatedImageData.image_id) {
                return { ...imageData, ...updatedImageData };
              }
              return imageData;
            });
            return { ...album, image_data: updatedImages };
          });

          return {
            characterAlbums: {
              ...state.characterAlbums,
              [characterId]: updatedAlbums,
            },
          };
        });
      },
      unlockAlbumImage: async (imageData: IImageDataInAlbum, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => {
        const { updateAlbumImage } = get();
        updateAlbumImage(characterId, { ...imageData, unlockPending: true } );

        const res = await charactersApi.unlockImage(characterId, imageData.image_id);
        await get().updateGemBalance(res);

        if (!(res instanceof AxiosError)) {
          if (!res.unlock_successful) {
            if (res.subscription_required) {
              onPaywallHit(res as unknown as IUnlockImageResponse);
            }
            else {
              onNotEnoughBalance();
            }
            updateAlbumImage(characterId, { ...imageData, unlockPending: false } );
            return null;
          }

          const newImageData = {
            ...imageData, 
            unlockPending: false,
            image_locked: !res.unlock_successful,
            image_url: res.image_data ? res.image_data : imageData.image_url,
          }
          updateAlbumImage(characterId, newImageData);
          return newImageData;
        } else {
          updateAlbumImage(characterId, { ...imageData, unlockPending: false });

          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return null;
        }
      },
      generateAudio: async (messageId: number, characterId: number, messageText: string, isAuthenticated: boolean, onPaywallHit: (config: IAudioMessageQuotaResponse) => void): Promise<AudioGenerationResult> => {
        try {
          const response = await charactersApi.generateAudio(messageId, characterId, messageText);
          
          // Currently it use stream API, so gem_balance can't be returned as part of API response, 
          // instead of calling, 
          //    await get().updateGemBalance(response);
          // it needs to make an additional call to update gem balance
          await useUserStore.getState().getUserStats();

          if (response) {
            if ('audio_locked' in response) {
              if (response.subscription_required) {
                onPaywallHit(response as unknown as IAudioMessageQuotaResponse);
              }
              else {
                onNotEnoughBalance();
              }
              return { success: false, reason: 'audio_locked' };
            }
            // Check if the response is audio/mpeg or JSON
            if (response instanceof ArrayBuffer) {
              // It's an audio file
              const audioBlob = new Blob([response], { type: 'audio/mpeg' });
              return { success: true, audioBlob };
            } else if (typeof response === 'object') {
              // It's likely JSON
              if ('audio_locked' in response) {       
                onPaywallHit(response as unknown as IAudioMessageQuotaResponse);
                return { success: false, reason: 'audio_locked' };
              } else {
                console.error('Unexpected JSON response:', response);
                return { success: false, reason: 'other_error' };
              }
            } else {
              console.error('Unexpected response type:', typeof response);
              return { success: false, reason: 'other_error' };
            }
          } else {
            console.error('Response is undefined');
            return { success: false, reason: 'other_error' };
          }
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 402) {
            return { success: false, reason: 'insufficient_gems' };
          }
          console.error('Error generating audio:', error);
          return { success: false, reason: 'other_error' };
        }
      },
      unlockAudioMessages: async (quotaResponse: IAudioMessageQuotaResponse) => {
        if (!get().checkBalance(quotaResponse.gem_cost)) {
          return false;
        }
        const res = await charactersApi.unlockAudioMessages();
        await get().updateGemBalance(res);

        const isError = res instanceof AxiosError;
        if (isError) {
          showToast(res.response?.data.detail || 'Unable to unlock', ToastType.ERROR);
          return false;
        }
        else {
          const success = !isError && res.unlock_successful;    
          if (!success) {
            showToast('Unable to unlock', ToastType.ERROR);
          }
          return success;
        }
      },
      createCharacter: async (choices: Record<string, any>, onPaywallHit: (config: ICharacterQuotaResponse) => void, variant?: string | null) => {
        const res = await charactersApi.createCharacter(choices, variant);
        await get().updateGemBalance(res);
        
        if (res instanceof AxiosError) {
          // Handle API error
          const errorMessage = res.response?.data?.detail || res.message;
          showToast(`Character creation failed. ${errorMessage}`, ToastType.ERROR);
          return null;
        }

        if (res && 'character_locked' in res) {
          if (res.subscription_required) {
            onPaywallHit(res);
          }
          else {
            onNotEnoughBalance();
          }
          return null;
        }
        
        if (res && 'character' in res) {
          const returnedCharacter = res.character;
          // add character to the list, update existing if already exists
          set(state => ({
            characters: state.characters.some(char => char.id === returnedCharacter.id)
              ? state.characters.map(char => char.id === returnedCharacter.id ? returnedCharacter : char)
              : [...state.characters, returnedCharacter]
          }));
          return res;
        }

        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },
      renameCharacter: async (characterId: number, name: string): Promise<ICharacter | null> => {
        const res = await charactersApi.renameCharacter(characterId, name);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to rename', ToastType.ERROR);
          return null;
        }
        if (res && 'character' in res) {
          const returnedCharacter : ICharacter = res.character as ICharacter;
          // add character to the list, update existing if already exists
          set(state => ({
            characters: state.characters.some(char => char.id === returnedCharacter.id)
              ? state.characters.map(char => char.id === returnedCharacter.id ? returnedCharacter : char)
              : [...state.characters, returnedCharacter]
          }));
          return returnedCharacter;
        }
        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },
      deleteCharacter: async (characterId: number): Promise<IDeleteCharacterResponse | null> => {
        const res = await charactersApi.deleteCharacter(characterId);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to delete', ToastType.ERROR);
          return null;
        }
        if (res && 'character_deleted' in res) {
          const returnedCharacter : IDeleteCharacterResponse = res as IDeleteCharacterResponse;
          // delete character from the list
          set(state => ({
            characters: state.characters.some(char => char.id === returnedCharacter.character_deleted)
              ? state.characters.filter(char => char.id !== returnedCharacter.character_deleted)
              : state.characters
          }));
          return returnedCharacter;
        }
        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },
      unlockCharacters: async (quotaResponse: ICharacterQuotaResponse) => {
        const res = await charactersApi.unlockCharacters();
        await get().updateGemBalance(res);
        if (res instanceof AxiosError) {
          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }
        
        if (!res.unlock_successful) {
          onNotEnoughBalance();
          return false;
        }
        else {
          return true;
        }
      },
      unlockImageGen: async (quotaResponse: IImageGenQuotaResponse) => {
        if (!get().checkBalance(quotaResponse.gem_cost)) {
          return false;
        }
        const res = await charactersApi.unlockImageGen();
        await get().updateGemBalance(res);
        if (res instanceof AxiosError) {
          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }
        
        if (!res.unlock_successful) {
          onNotEnoughBalance();
          return false;
        }
        else {
          return true;
        }
      },
      updateGemBalance: async(res: any) => {
        if ('gem_balance' in res && res.gem_balance && typeof res.gem_balance === 'number') {
          await useUserStore.getState().setUserStats({ gem_balance: res.gem_balance });
        }
      },
      getTotalPendingImages: (characterId: number) => {
        let totalPending = 0;
        if (get().characterAlbums[characterId]) {
          totalPending = get().characterAlbums[characterId].reduce((total, album) => {
            return total + (album.images_pending ?? 0);
          }, 0);
        }
        return totalPending;
      },
      getCharacterNewImages(characterId: number) {
        return get().characterNewImages[characterId];
      },
      clearCharacterNewImages(characterId: number) {
        set(state => ({
          ...state,
          characterNewImages: {
            ...state.characterNewImages,
            [characterId]: 0
          }
        }));
      },
      setCharacterActiveChatProfile: async (characterId: number, chatProfileId: number) => {
        const res = await charactersApi.setCharacterActiveChatProfile(characterId, chatProfileId);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to set active chat profile', ToastType.ERROR);
          return false;
        }
        else {
          set({ characterChatProfiles: { ...get().characterChatProfiles, [characterId]: res.profile_id } });
          return true;
        }
      },
      regenerateLastMessageStream: async (characterId, onQuotaHit, user_input) => {
        // Check if streaming is disabled via environment variable
        const enableStreaming = isChatStreamingEnabled();
        
        // If streaming is disabled, use the regular regenerateLastMessage
        if (!enableStreaming) {
          return get().regenerateLastMessage(characterId, onQuotaHit, user_input);
        }

        let initialMessage: IChatMessage;

        set(state => {
          const { chatHistory } = state;
          const { history } = chatHistory[characterId];
          const lastMessage = history.findLast(m => m.sender === 'character');
          const lastUserMessage = history.findLast(m => m.sender === 'user');

          // clear last char message data and save copy of the message we override in case of errors
          if (lastMessage) {
            initialMessage = { ...lastMessage };
            lastMessage.image_data = undefined;
            lastMessage.text = '';

            // Update the last user message if user_input is provided
            if (user_input && lastUserMessage) {
              lastUserMessage.text = user_input;
            }
          }

          return { sendingMessage: true, regenerating: true, chatHistory };
        });

        try {
          const streamResponse = await charactersApi.regenerateLastMessageStream(characterId, user_input);
          
          try {
            const reader = streamResponse.body?.getReader();
            if (!reader) {
              throw new Error('No reader available');
            }
            
            let decoder = new TextDecoder();
            let buffer = '';
            let completeText = ''; // Start with empty text for the regenerated message
            let isFirstChunk = true;
            let receivedAnyChunks = false;
            let chunkCount = 0;

            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                break;
              }
              
              buffer += decoder.decode(value, { stream: true });
              
              // Process complete lines
              let lines = buffer.split('\n');
              buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer
              
              for (const line of lines) {
                if (!line.trim()) continue;
                
                try {
                  const data = JSON.parse(line);
                  
                  if (data.text_chunk) {
                    chunkCount++;
                    
                    // If this is the first chunk, set streamingMessage to true
                    if (isFirstChunk) {
                      set({ streamingMessage: true, regenerating: false });
                      isFirstChunk = false;
                    }
                    
                    // Mark that we've received at least one chunk
                    receivedAnyChunks = true;
                    
                    // Handle streaming chunk - append to the regenerated message text
                    completeText += data.text_chunk;
                    
                    // Update the message with the accumulated text
                    set(state => {
                      const { chatHistory } = state;
                      const { history } = chatHistory[characterId];
                      const lastMessage = history.findLast(m => m.sender === 'character');
                      
                      if (lastMessage) {
                        lastMessage.text = completeText;
                      }
                      
                      return { chatHistory };
                    });
                  } else if (data.id) {
                    // This is the final message with full data
                    receivedAnyChunks = true;
                    
                    set(state => {
                      const { chatHistory } = state;
                      const { history } = chatHistory[characterId];
                      const lastMessage = history.findLast(m => m.sender === 'character');
                      
                      if (lastMessage) {
                        // Update with all the final metadata
                        Object.assign(lastMessage, {
                          id: data.id,
                          text: data.text || completeText, // Use complete text if no final text provided
                          time_sent: data.time_sent,
                          image_data: data.image_data,
                          image_locked: data.image_locked,
                          gem_cost: data.gem_cost,
                          image_id: data.image_id,
                          generated_by_others: data.generated_by_others,
                          image_request_id: data.image_request_id
                        });
                      }
                      
                      return { chatHistory };
                    });
                  }
                } catch (e) {
                  console.error('Error parsing JSON:', e, 'Line:', line);
                }
              }
            }
            
            // If we didn't receive any chunks, restore the original message and show an error
            if (!receivedAnyChunks) {
              
              // Restore the original message
              set(state => {
                const { chatHistory } = state;
                const { history } = chatHistory[characterId];
                const lastMessage = history.findLast(m => m.sender === 'character');
                
                if (lastMessage) {
                  Object.assign(lastMessage, initialMessage);
                }
                
                return { chatHistory };
              });
              
              // Show an error message
              showToast('Failed to regenerate message. Please try again.', ToastType.ERROR);
            }
            
          } catch (error) {
            console.error('Error processing stream:', error);
            set({ error });
            
            // Restore the original message in case of error
            set(state => {
              const { chatHistory } = state;
              const { history } = chatHistory[characterId];
              const lastMessage = history.findLast(m => m.sender === 'character');
              
              if (lastMessage) {
                Object.assign(lastMessage, initialMessage);
              }
              
              return { chatHistory };
            });
            
            // Fallback to the regular regenerateLastMessage function
            console.log('Falling back to regular regenerateLastMessage due to stream processing error');
            get().regenerateLastMessage(characterId, onQuotaHit, user_input);
            return;
          }
        } catch (error) {
          console.error('Error in regenerateLastMessageStream:', error);
          set({ error });
          
          // Restore the original message in case of error
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[characterId];
            const lastMessage = history.findLast(m => m.sender === 'character');
            
            if (lastMessage) {
              Object.assign(lastMessage, initialMessage);
            }
            
            return { chatHistory };
          });
          
          // Fallback to the regular regenerateLastMessage function
          console.log('Falling back to regular regenerateLastMessage due to general error');
          get().regenerateLastMessage(characterId, onQuotaHit, user_input);
        } finally {
          set({ sendingMessage: false, streamingMessage: false, regenerating: false });
        }
      },
    }),
    {
      name: charactersStorePersistKey,
      partialize: state => ({
        characters: state.characters,
        recentChats: state.recentChats,
        chatHistory: state.chatHistory,
        characterAlbums: state.characterAlbums,
      }),
      onRehydrateStorage: () => (state) => {
        if (state) {
          // Prune expired messages for all characters in the chat history
          Object.keys(state.chatHistory).forEach(characterId => {
            state.pruneExpiredChatHistory(Number(characterId));
          });
        }
      },
    },
  ),
);
