import userApi from '@/api/user';
import { callWithLoading, stateBase } from '@/helpers/store';
import { UserProfile, UserStats } from '@/models/user';
import { StateBase } from '@/types/store';
import { UpdateUserProfileRequest } from '@/types/user';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { useCharactersStore } from './characters';
import { useProductsStore } from './products';
import { ChatProfile } from '@/models/user';

export interface UserState extends StateBase {
  user: UserProfile | null;
  userStats: UserStats;
  userChatProfiles: ChatProfile[];
  updateGemBalance: (balance: number) => void;
  getUserProfile: () => void;
  updateUserProfile: (data: UpdateUserProfileRequest) => void;
  getUserChatProfiles: () => void;
  createUserChatProfile: (data: ChatProfile) => Promise<boolean>;
  updateUserChatProfile: (data: ChatProfile) => Promise<boolean>;
  deleteUserChatProfile: (id: number) => Promise<boolean>;
  getUserStats: () => Promise<void>;
  setUserStats: (stats: Partial<UserStats>) => void;
  updateEmailVerified: (email: string) => Promise<void>;
  deleteAccount: (notes?: string) => Promise<boolean>;
  reset: () => void;
}

export const useUserStore = create<UserState>()((set, get) => ({
  ...stateBase,
  user: null,
  userStats: { 
    gem_balance: 0, 
    login_email: null, 
    email_verified: null, 
    created_at: null, 
    is_internal: false, 
    subscription: null,
    marketing_opt_in: false
  },
  userChatProfiles: [],
  initialize: async () => {
    const { initialized, getUserProfile, getUserStats, getUserChatProfiles } = get();

    if (!initialized) {
      await Promise.all([getUserProfile(), getUserStats(), getUserChatProfiles()]);
      useCharactersStore.getState().getUserRecentChats();
      useProductsStore.getState().getProductsAndPromotions();
      set({ initialized: true });
    }
  },
  updateGemBalance: (balance: number) => set({ userStats: { ...get().userStats, gem_balance: balance } }),
  getUserProfile: async () => {
    const res = await callWithLoading<UserProfile | AxiosError<any, any>>(userApi.getUserProfile(), set);
    if (res instanceof AxiosError) {
      if (res.response?.status !== 401) {
        showToast(`Can\'t get user profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      }
    } else {
      set({ user: res });
    }
  },
  updateUserProfile: async (data: UpdateUserProfileRequest) => {
    const res = await callWithLoading<UserProfile | AxiosError<any, any>>(userApi.updateUserProfile(data), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t update user profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      set({ user: res });
      showToast('Profile is successfully updated!', ToastType.SUCCESS);
    }
  },
  getUserChatProfiles: async () => {
    const res = await callWithLoading<ChatProfile[] | AxiosError<any, any>>(userApi.getUserChatProfiles(), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get user chat profiles: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      set({ userChatProfiles: res });
    }
  },
  createUserChatProfile: async (data: ChatProfile) => {
    const res = await callWithLoading<ChatProfile | AxiosError<any, any>>(userApi.createUserChatProfile(data), set);
    if (res instanceof AxiosError) {
      showToast(`Error: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else if (res) {
      set({ userChatProfiles: [...get().userChatProfiles, res] });
      return true;
    }
    return false;
  },
  updateUserChatProfile: async (data: ChatProfile) => {
    const res = await callWithLoading<ChatProfile | AxiosError<any, any>>(userApi.updateUserChatProfile(data), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t update user chat profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else if (res) {
      set({ userChatProfiles: get().userChatProfiles.map(profile => profile.id === res.id ? res : profile) });
      return true;
    }
    return false;
  },
  deleteUserChatProfile: async (id: number) => {
    const res = await callWithLoading<void | AxiosError<any, any>>(userApi.deleteUserChatProfile(id), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t delete user chat profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else if (res) {
      set({ userChatProfiles: get().userChatProfiles.filter(profile => profile.id !== id) });
      return true;
    }
    return false
  },
  setUserStats: stats => set(state => ({ userStats: { ...state.userStats, ...stats } })),
  getUserStats: async () => {
    const res = await userApi.getUserStats();

    if (res instanceof AxiosError) {
      showToast(`Can\'t get user stats: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      set({ userStats: res });
    }
  },
  updateEmailVerified: async (email: string) => {
    const res = await userApi.updateEmailVerified(email);

    if (res instanceof AxiosError) {
      showToast(`Can\'t update email verified: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      showToast('Email verified!', ToastType.SUCCESS);
    }
  },
  deleteAccount: async (notes?: string) => {
    const res = await userApi.deleteAccount(notes || '');

    if (res instanceof AxiosError) {
      return false;
    } else {
      return true;
    }

  },
  reset: async () => set({ ...stateBase, user: null }),
}));

