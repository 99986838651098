/**
 * Utilities for handling environment variables
 */

/**
 * Checks if chat streaming is enabled based on the NEXT_PUBLIC_ENABLE_CHAT_STREAMING 
 * environment variable. Defaults to false if not set.
 */
export const isChatStreamingEnabled = (): boolean => {
  return process.env.NEXT_PUBLIC_ENABLE_CHAT_STREAMING === 'true';
}; 