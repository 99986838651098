import { CharacterCreationChoices } from "@/models/choices";

import { Camera, Palette, Venus, Mars, VenusAndMars, ThumbsUp, Clock } from 'lucide-react';
export const CharacterFilterTags = [
  {
    id: 'all',
    tag: null,
    name: 'All',
    icon: null,
  },
  {
    id: 'realistic',
    tag: 'style:realistic',
    name: 'Realistic',
    icon: Camera,
  },
  {
    id: 'artistic',
    tag: 'style:artistic',
    name: 'Anime',
    icon: Palette,
  },
  {
    id: 'female',
    tag: 'gender:female',
    name: 'Female',
    icon: Venus,
  },
  {
    id: 'male',
    tag: 'gender:male',
    name: 'Male',
    icon: Mars,
  },
  {
    id: 'futas',
    tag: 'gender:futa',
    name: 'Futa',
    icon: VenusAndMars,
  },
]

export enum CharacterSortType {
  POPULAR = 'popular',
  NEWEST = 'newest',
}

export const CharacterSortTags = [
  {
    id: CharacterSortType.POPULAR,
    tags: ['sort:popular'],
    name: 'Popular',
    icon: ThumbsUp,
  },
  {
    id: CharacterSortType.NEWEST,
    tags: ['sort:newest'],
    name: 'Newest',
    icon: Clock,
  },
]

export interface ICharacter {
  id: number;
  unique_code: string; // Add this line (use unique_code as per backend)
  seo_slug: string;    // Add this line
  name: string;
  profile: string;
  subtitle: string;
  description: string;
  avatar_url: string;
  avatar_urls?: string[];
  voice_enabled?: boolean;
  created_at: Date;
  created_by_user: boolean;
  creator_username: string;
  tags?: string[];
  creation_choices?: Record<string, any>;
  dynamic_image?: boolean;
  sort_rank?: number;
  is_public: boolean;
}

export interface IImageDataInAlbum {
  image_id: number;
  image_url: string;
  image_locked: boolean;
  gem_cost: number;
  unlockPending?: boolean;
  generated_by_others?: boolean;
}

export interface ICharacterAlbum {
  album_id: number;
  character_id: number;
  title: string;
  description: string;
  locked: boolean;
  locked_description: string;
  sort_order: number;
  image_urls?: string[];
  images_unlocked: number;
  image_data?: IImageDataInAlbum[];
  images_pending?: number;
  updated_at?: Date;
}

export interface IDeleteCharacterResponse {
  character_deleted: number;
}

export interface IDeleteImageResponse {
  images_deleted: number[];
}

export interface ICharacterUnlockProduct {
  product_id: number;
  usd_cents: number;
  remaining_time?: number;
}

export interface ICharacterQuotaResponse {
  character_locked: boolean;
  subscription_required?: boolean;
  gem_cost: number;
  gem_balance?: number;
  unlock_product?: ICharacterUnlockProduct;
}

export interface IImageMetadata {
  image_id: number;
  image_request_id: number;
  image_prompt: string;
}

export type ICharacterCreationResponse = { character: ICharacter } | ICharacterQuotaResponse;

export interface SimplifiedCharacter {
  id: string;
  unique_code?: string; // Add optional unique_code
  seo_slug?: string;  // Add optional seo_slug
  avatar_url: string;
  name?: string;
  image_request_id?: number;
  gender?: string;
}

export interface ICharacterCreationChoices {
  [key: number]: CharacterCreationChoices;
}

export function areCharactersEqual(char1?: ICharacter | null, char2?: ICharacter | null): boolean {
  if (!char1 && !char2) {
    return true;
  }
  if (!char1 || !char2) {
    return false;
  }
  return (
    char1.id === char2.id &&
    char1.name === char2.name &&
    char1.profile === char2.profile &&
    char1.subtitle === char2.subtitle &&
    char1.description === char2.description &&
    char1.avatar_url === char2.avatar_url &&
    JSON.stringify(char1.avatar_urls) === JSON.stringify(char2.avatar_urls) &&
    char1.voice_enabled === char2.voice_enabled &&
    char1.created_by_user === char2.created_by_user &&
    char1.creator_username === char2.creator_username &&
    JSON.stringify(char1.creation_choices) === JSON.stringify(char2.creation_choices) &&
    char1.dynamic_image === char2.dynamic_image
  );
}

// Default value for creator username when none is provided
export const DEFAULT_CREATOR_USERNAME = "Unknown";
