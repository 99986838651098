import api, { callApiOrThrow } from './config';
import { IError } from '@/models/common';
import { ICharacterUnlockedImages, IImageGenerationRequest, IImageRequestFilter, ISEOImage } from '@/models/image';
import { IDeleteImageResponse, IImageMetadata } from '@/models/characters';

interface IImageFeedParams {
  tags?: string[];
  cursorUuid?: string;
  cursorSeoSlug?: string;
  limit?: number;
}

const imageApi = {
  async getUnlockedImages() {
    const url = '/image/get_unlocked_images';
    return callApiOrThrow<ICharacterUnlockedImages, IError>(api.get(url));
  },
  async getImageRequest(id: number) {
    const url = `/image/request/${id}`;
    return callApiOrThrow<IImageGenerationRequest, IError>(api.get(url));
  },
  async getFilteredImageRequests(filters: IImageRequestFilter) {
    const url = `/image/filter_requests`;
    const simplifiedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value && value !== undefined && value !== '') {
        acc[key] = typeof value === 'object' && value !== null ? value.value : value;
      } else {
        delete acc[key];
      }
      return acc;
    }, {} as Record<string, any>);
    return callApiOrThrow<IImageGenerationRequest[], IError>(api.get(url, { params: simplifiedFilters }));
  },
  async getLatestImages({
    tags,
    cursorUuid,
    cursorSeoSlug,
    limit = 50,
  }: IImageFeedParams = {}): Promise<ISEOImage[]> {
    const url = '/image/image_feed';
    const params: Record<string, any> = {};

    if (tags?.length) params.tags = tags;
    if (cursorUuid) params.cursor_uuid = cursorUuid;
    if (cursorSeoSlug) params.cursor_slug = cursorSeoSlug;
    if (limit) params.limit = limit;

    return await callApiOrThrow<ISEOImage[], IError>(
      api.get(url, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      })
    );
  },
  async getImageMetadata(image_id: number) {
    const url = `/image/${image_id}/meta`;
    return callApiOrThrow<IImageMetadata, IError>(api.get(url));
  },
  async deleteImages(image_ids: number[], request_ids: number[], active: boolean) {
    const url = `/image/delete?active=${active}`;
    return callApiOrThrow<IDeleteImageResponse, IError>(api.post(url, { image_ids, request_ids }));
  },
  async getPublicImage(uuid: string, seo_slug: string): Promise<ISEOImage> {
    const url = `/image/public_image/${uuid}/${seo_slug}`;
    return callApiOrThrow<ISEOImage, IError>(api.get(url));
  },
  async getRelatedImages(uuid: string, seo_slug: string): Promise<ISEOImage[]> {
    const url = `/image/related_images/${uuid}/${seo_slug}`;
    return callApiOrThrow<ISEOImage[], IError>(api.get(url));
  },
};

export default imageApi;
