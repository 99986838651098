import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { AppLayout } from '@/components/layout/AppLayout';
import '@/styles/globals.css';
import ErrorBoundary from '@/components/ErrorBoundary';
// import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import posthog from 'posthog-js'
import { useUserStore } from '@/stores/user';
import { PostHogProvider } from 'posthog-js/react'
import { setUtmCookies } from '@/utils/cookie';
import { DefaultSeo } from 'next-seo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const DynamicToast = dynamic<{}>(() => import('@/components/Toast').then((mod) => mod.Toast), { ssr: false });

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    },
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    person_profiles: 'identified_only',
  })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
    },
  },
});

export default function App({ Component, router, pageProps: { session, ...pageProps } }: AppProps) {
  const pathName = router.pathname;
  const baseUrl = (process.env.NEXT_PUBLIC_AUTH_URL || 'http://thotchat.ai').replace(/\/+$/, '');
  const canonicalUrl = new URL(router.asPath.replace(/^\//, ''), baseUrl).toString();

  useEffect(() => {
    // Google Tag Manager
    // const tagManagerArgs = {
    //   gtmId: process.env.NEXT_PUBLIC_GTM_ID
    // }
    // TagManager.initialize(tagManagerArgs);

    // PostHog: Track page views
    const IGNORE_EVENT_AFTER = 24 * 60 * 60 * 1000; // 24 hours
    const allowedUrls = [
      '/v2/character/create',
      '/v3/character/create',
      '/character/',
      '/chat/',
      '/creations',
      '/premium',
      '/images/feed',
    ];

    const handleRouteChange = (url: string) => {
      const isAllowedUrl = allowedUrls.some(prefix => url.startsWith(prefix));
      const userCreatedAt = useUserStore.getState().userStats?.created_at;
      const shouldIgnore = (userCreatedAt && (new Date().getTime() - new Date(userCreatedAt).getTime()) > IGNORE_EVENT_AFTER);
      if (isAllowedUrl && !shouldIgnore) {
        posthog?.capture('$pageview');
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, []);

  useEffect(() => {
    // Set UTM cookies on initial load
    setUtmCookies();

    // Set UTM cookies only when URL contains UTM parameters
    const handleRouteChange = (url: string) => {
      if (url.includes('utm_')) {
        setUtmCookies();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider client={posthog}>
        <ErrorBoundary>
          <SessionProvider session={session}>
            <DefaultSeo
              defaultTitle="ThotChat - NSFW AI Chat"
              dangerouslySetAllPagesToNoIndex={true}
              dangerouslySetAllPagesToNoFollow={true}
              canonical={canonicalUrl}
              additionalMetaTags={[
                {
                  name: 'viewport',
                  content: 'width=device-width, initial-scale=1.0'
                }
              ]}
              additionalLinkTags={[
                {
                  rel: 'icon',
                  href: '/favicon.ico',
                  type: 'image/x-icon'
                },
                {
                  rel: 'icon',
                  type: 'image/svg+xml',
                  href: '/favicon.svg'
                },
                {
                  rel: 'icon',
                  type: 'image/png',
                  sizes: '96x96',
                  href: '/favicon-96x96.png'
                },
                {
                  rel: 'icon',
                  type: 'image/png',
                  sizes: '32x32',
                  href: '/favicon-32x32.png'
                },
                {
                  rel: 'icon',
                  type: 'image/png',
                  sizes: '16x16',
                  href: '/favicon-16x16.png'
                },
                {
                  rel: 'apple-touch-icon',
                  sizes: '180x180',
                  href: '/apple-touch-icon.png'
                }
              ]}
            />
            <AppLayout pathName={pathName}>
              <Component {...pageProps} />
              <DynamicToast />
            </AppLayout>
          </SessionProvider>
        </ErrorBoundary>
      </PostHogProvider>
    </QueryClientProvider>
  );
}