import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { Button } from '../buttons/Button';
import { BaseModal, closeModal } from '../BaseModal';
import { useUserStore } from '@/stores/user';
import ModalBg from '@/assets/images/vector/modal-bg.svg';
import { Inbox } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState, useRef, useEffect } from 'react';
import itemsApi from '@/api/items';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';

type WizardStep = 'initial' | 'checkSpam' | 'success' | 'support';

export const EmailConfirmationModal: React.FC = () => {
  const { userStats } = useUserStore();
  const router = useRouter();
  const [currentStep, setCurrentStep] = useState<WizardStep>('initial');
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'open') {
            const isOpen = (mutation.target as HTMLDialogElement).hasAttribute('open');
            if (isOpen) {
              setCurrentStep('initial');
            }
          }
        });
      });

      observer.observe(modalRef.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const handleYes = () => {
    setCurrentStep('success');
  };

  const handleNo = () => {
    setCurrentStep('checkSpam');
  };

  const handleSpamYes = () => {
    setCurrentStep('success');
  };

  const handleSpamNo = () => {
    setCurrentStep('support');
  };

  const handleClose = () => {
    closeModal(Modals.EmailConfirmation);
    router.push('/');
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 'initial':
        return (
          <>
            <Text variant="h2" className="mt-4 mb-1 text-white text-center">
              Check Your Email
            </Text>
            <Text variant="body-1" className="mb-6 text-white opacity-80 text-center">
              We've sent your special offer to {userStats.google_email}.<br />
              Did you see the email?
            </Text>
            <div className="flex gap-4 w-full justify-center">
              <Button
                onClick={handleYes}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  Yes
                </Text>
              </Button>
              <Button
                onClick={handleNo}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  No
                </Text>
              </Button>
            </div>
          </>
        );
      case 'checkSpam':
        return (
          <>
            <Text variant="h2" className="mt-4 mb-1 text-white text-center">
              Check Your Spam Folder
            </Text>
            <Text variant="body-1" className="mb-6 text-white opacity-80 text-center">
              Check your spam folder. Did you see it?
            </Text>
            <div className="flex gap-4 w-full justify-center">
              <Button
                onClick={handleSpamYes}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  Yes
                </Text>
              </Button>
              <Button
                onClick={handleSpamNo}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  No
                </Text>
              </Button>
            </div>
          </>
        );
      case 'success':
        return (
          <>
            <Text variant="h2" className="mt-4 mb-1 text-white text-center">
              Great!
            </Text>
            <Text variant="body-1" className="mb-6 text-white opacity-80 text-center">
              Enjoy your 200💎
            </Text>
            <div className="flex gap-4 w-full justify-center">
              <Button
                onClick={handleClose}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  Thanks!
                </Text>
              </Button>
            </div>
          </>
        );
      case 'support':
        return (
          <>
            <Text variant="h2" className="mt-4 mb-1 text-white text-center">
              Email Not Found
            </Text>
            <Text variant="body-1" className="mb-6 text-white opacity-80 text-center">
              Sorry to hear it's not there yet. Please wait five minutes then check again. If you still don't see it, please reach out to support@thotchat.ai
            </Text>
            <div className="flex gap-4 w-full justify-center">
              <Button
                onClick={handleClose}
                className="w-[40%]"
                wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  OK
                </Text>
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <BaseModal 
      ref={modalRef}
      className="overflow-hidden" 
      id={Modals.EmailConfirmation} 
      preventOutsideClose={true}
    >
      <div className="relative rounded-[32px] w-[95%] sm:w-[480px] p-0">
        <div className="relative bg-black-200 rounded-[inherit] p-2 py-5 sm:p-8 z-10 overflow-hidden">
          <ModalBg className="absolute z-0 left-0 top-0 w-full" />
          <div className="relative z-10">
            <div className="flex flex-col items-center">
              <Inbox className="w-16 h-16 text-pink mb-4" />
              {renderStepContent()}
            </div>
          </div>
        </div>
        <div className={'absolute left-[3px] top-[3px] h-full w-full border border-black rounded-[34px] z-0 bg-pink'} />
      </div>
    </BaseModal>
  );
};