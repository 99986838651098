import { cn } from '@/lib/utils';
import { PropsWithChildren, forwardRef, memo, useEffect, useState } from 'react';

type Props = {
  id: string;
  className?: string;
  onClose?: () => void;
  preventOutsideClose?: boolean;
};

const RefModal = forwardRef<HTMLDialogElement, PropsWithChildren<Props>>(function Modal(
  { id, children, className, onClose, preventOutsideClose = false },
  ref,
) {
  // Add a client-side only state
  const [isMounted, setIsMounted] = useState(false);
  
  // Only run on client-side
  useEffect(() => {
    setIsMounted(true);
    
    // Polyfill for dialog element on mobile browsers that might not fully support it
    if (typeof window !== 'undefined') {
      const dialogPolyfill = async () => {
        try {
          // Check if the browser supports dialog
          const dialog = document.createElement('dialog');
          if (!dialog.showModal) {
            // If not, add a simple polyfill for show/close methods
            HTMLDialogElement.prototype.show = HTMLDialogElement.prototype.show || function(this: HTMLDialogElement) {
              this.setAttribute('open', '');
              this.style.display = 'flex';
            };
            
            HTMLDialogElement.prototype.showModal = HTMLDialogElement.prototype.showModal || function(this: HTMLDialogElement) {
              this.setAttribute('open', '');
              this.style.display = 'flex';
            };
            
            HTMLDialogElement.prototype.close = HTMLDialogElement.prototype.close || function(this: HTMLDialogElement) {
              this.removeAttribute('open');
              this.style.display = 'none';
              
              // Dispatch close event
              const closeEvent = new Event('close');
              this.dispatchEvent(closeEvent);
            };
          }
        } catch (e) {
          console.error('Dialog polyfill error:', e);
        }
      };
      
      dialogPolyfill();
    }
  }, []);

  if (!isMounted) {
    // Return null or a placeholder during SSR
    return null;
  }

  return (
    <dialog 
      id={id} 
      ref={ref} 
      className={cn('modal bg-black/[.5] z-40 backdrop-filter backdrop-blur-md bg-black bg-opacity-50', className)} 
      onClose={() => {
        onClose && onClose();
      }}
      onClick={(e) => {
        if (preventOutsideClose) {
          e.stopPropagation();
        }
      }}
    >
      {children}
      <form 
        method="dialog" 
        className="modal-backdrop absolute left-0 top-0 w-full h-full"
        onSubmit={(e) => {
          if (preventOutsideClose) {
            e.preventDefault();
          }
        }}
      >
        <button className="cursor-default" aria-label="close"></button>
      </form>
    </dialog>
  );
});

export const BaseModal = memo(RefModal);

// Check if we're on the client side before manipulating the DOM
const isClient = typeof window !== 'undefined';

export function openModal(id: string) {
  if (!isClient) return;
  
  // Use setTimeout to ensure the DOM is ready
  setTimeout(() => {
    const modal = document.getElementById(id) as HTMLDialogElement;
    if (modal) {
      try {
        // Try to use showModal first (better for accessibility)
        if (typeof modal.showModal === 'function') {
          modal.showModal();
        } else {
          modal.show();
        }
        
        if (window) {
          window.dispatchEvent(new CustomEvent('modalOpen', {detail: {id}}));
        }
      } catch (e) {
        console.error('Error opening modal:', e);
        // Fallback method if showModal fails
        modal.setAttribute('open', '');
        modal.style.display = 'flex';
      }
    }
  }, 0);
}

export function closeModal(id: string) {
  if (!isClient) return;
  
  // Use setTimeout to ensure the DOM is ready
  setTimeout(() => {
    const modal = document.getElementById(id) as HTMLDialogElement;
    if (modal) {
      try {
        modal.close();
        
        if (window) {
          window.dispatchEvent(new CustomEvent('modalClose', {detail: {id}}));
        }
      } catch (e) {
        console.error('Error closing modal:', e);
        // Fallback method if close fails
        modal.removeAttribute('open');
        modal.style.display = 'none';
      }
    }
  }, 0);
}
