import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import crystalImage from '@/assets/images/crystal-image.png';
import { Button } from '../buttons/Button';
import { BaseModal, closeModal } from '../BaseModal';
import { useUserStore } from '@/stores/user';
import diamondsAnimation from '@/assets/animations/diamonds.json';
import ModalBg from '@/assets/images/vector/modal-bg.svg';
import { useEffect, useRef, useState } from 'react';
import { GemIcon, Telescope, CalendarCheck, CircleX } from 'lucide-react';
import { AnimatedCounter } from '../AnimatedCounter';
import CloseIcon from '@/assets/icons/close-icon.svg';
import itemsApi from '@/api/items';

export const SuccessSubscriptionModal: React.FC = () => {
  const router = useRouter();
  const { userStats } = useUserStore();
  const modal = useRef<HTMLDialogElement>(null);
  const animation = useRef<Player | null>(null);
  const [email, setEmail] = useState(userStats.google_email || '');
  const [subscribeToOffers, setSubscribeToOffers] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEligibleForOffer, setIsEligibleForOffer] = useState<boolean | null>(null);

  useEffect(() => {
    if (modal.current) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'open') {
            const isOpen = (mutation.target as HTMLDivElement).hasAttribute('open');
            if (isOpen) {
              animation?.current?.stop();
              animation?.current?.play();
              
              // Check eligibility when modal opens
              checkEligibility();
            }
          }
        });
      });

      observer.observe(modal.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [modal]);

  const checkEligibility = async () => {
    try {
      const response = await itemsApi.checkPostSubscriptionOfferEligibility();
      if ('is_eligible' in response) {
        setIsEligibleForOffer(response.is_eligible);
      } else {
        setIsEligibleForOffer(false);
      }
    } catch (err) {
      console.error('Failed to check offer eligibility:', err);
      setIsEligibleForOffer(false);
    }
  };

  const handleClaimGems = async () => {
    if (isEligibleForOffer && !subscribeToOffers) {
      setError('Please opt in to special offers to receive your free gems');
      return;
    }

    if (!userStats.google_email) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await itemsApi.postSubscription(userStats.google_email);

      if ('success' in response && !response.success) {
        throw new Error(response.message || 'Failed to claim gems');
      }

      closeModal(Modals.SuccessSubscription);
      // Show the email confirmation modal
      const emailModal = document.getElementById(Modals.EmailConfirmation) as HTMLDialogElement;
      if (emailModal) emailModal.showModal();
    } catch (err) {
      setError('Failed to claim gems. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal className="overflow-hidden" ref={modal} id={Modals.SuccessSubscription} preventOutsideClose={true}>
      <div 
        className="absolute flex flex-col items-center justify-center w-full h-full"
        onClick={(e) => e.stopPropagation()}
      />
      <div className="relative rounded-[32px] w-[95%] sm:w-[480px] p-0">
        <div className="relative bg-black-200 rounded-[inherit] p-2 py-5 sm:p-8 z-10 overflow-hidden max-h-[500px]">
          <ModalBg className="absolute z-0 left-0 top-0 w-full" />
          <div className="relative z-10">
            <div className="flex flex-col items-center">
              <Text variant="h2" className="mt-4 mb-1 text-white text-center">
                {"Subscription Successful!"}
              </Text>
              <Text variant="body-1" className="mb-4 text-white opacity-80 text-center">
                {"Welcome to thotchat.ai Premium!"}
              </Text>

              <div className="flex flex-row w-full items-center mb-4">
                <GemIcon className="w-6 h-6 mr-2 text-pink" />
                <Text className="text-white w-11/12 mt-1" variant="h6">
                  You have received <span className="text-pink">1000 gems</span> as a part of your subscription!
                </Text>
              </div>

              <div className="flex flex-row w-full items-center mb-4">
                <CalendarCheck className="w-6 h-6 mr-2 text-pink" />
                <Text className="text-white w-11/12 mt-1" variant="h6">
                  Claim <span className="text-pink">25 FREE gems</span> every day!
                </Text>
              </div>

              {isEligibleForOffer ? (
                <>
                  <div className="flex flex-row w-full items-center mb-4">
                    <Telescope className="w-6 h-6 mr-2 text-pink" />
                    <Text className="text-white w-11/12 mt-1" variant="h6">
                      Join our email list to get 💎200
                    </Text>
                  </div>

                  <div className="w-[80%] flex flex-col mt-4">
                    <label className="flex items-center space-x-2 mb-4 cursor-pointer pl-1">
                      <input
                        type="checkbox"
                        checked={subscribeToOffers}
                        onChange={(e) => {
                          setSubscribeToOffers(e.target.checked);
                          setError(null);
                        }}
                        className="w-4 h-4 accent-pink"
                      />
                      <Text className="text-white" variant="body-2">
                        Yes, I too am cool, send me those gems ✨
                      </Text>
                    </label>
                    
                    {error && (
                      <Text className="text-red-500 text-sm mb-2" variant="body-2">
                        {error}
                      </Text>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex flex-row w-full items-center mb-4">
                  <Telescope className="w-6 h-6 mr-2 text-pink" />
                  <Text className="text-white w-11/12 mt-1" variant="h6">
                    Enjoy exploring all the premium features and content!
                  </Text>
                </div>
              )}

              <div className="flex w-[80%] gap-4 mt-4">
                <Button
                  onClick={() => closeModal(Modals.SuccessSubscription)}
                  className="w-full"
                  wrapperClass="bg-black-300 group-active/button:bg-black py-2 sm:py-4"
                >
                  <Text className="text-white group-active/button:text-white" variant="btn-1">
                    No Thanks
                  </Text>
                </Button>

                <Button
                  onClick={handleClaimGems}
                  disabled={isLoading}
                  className="w-full"
                  wrapperClass="bg-pink group-active/button:bg-black py-2 sm:py-4"
                >
                  <Text className="text-white group-active/button:text-white" variant="btn-1">
                    {isLoading ? 'Processing...' : isEligibleForOffer ? 'Gimme 💎' : 'Explore Now'}
                  </Text>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={'absolute left-[3px] top-[3px] h-full w-full border border-black rounded-[34px] z-0 bg-pink'} />
        <Player
          ref={animation}
          keepLastFrame={true}
          className="absolute z-0 -left-[100px] -top-[100px]"
          autoplay={false}
          loop={false}
          speed={1}
          src={diamondsAnimation}
          style={{ height: '450px', width: '600px' }}
        />

        <button
          onClick={() => closeModal(Modals.SuccessSubscription)}
          className="absolute right-4 top-4 rounded-full bg-black/[0.5] border border-white/[0.5] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none z-20"
        >
          <CloseIcon />
        </button>
      </div>
    </BaseModal>
  );
};
