import api, { callApi, callApiOrThrow } from './config';
import { IError } from '@/models/common';

interface PostSubscriptionResponse {
  success: boolean;
  message?: string;
}

interface ClaimOfferResponse {
  message: string;
}

interface PostSubscriptionOfferEligibilityResponse {
  is_eligible: boolean;
}

const itemsApi = {
  async postSubscription(email: string) {
    const url = '/items/post-subscription';
    return callApi<PostSubscriptionResponse, IError>(api.post(url, { email }));
  },

  async resendPostSubscription(email: string) {
    const url = '/items/resend-post-subscription';
    return callApi<PostSubscriptionResponse, IError>(api.post(url, { email }));
  },

  async claimOffer(offerUuid: string) {
    const url = `/items/gimme/${offerUuid}`;
    return callApiOrThrow<ClaimOfferResponse, IError>(api.post(url));
  },

  async checkPostSubscriptionOfferEligibility() {
    const url = '/items/is-eligible-for-post-subscription-offer';
    return callApi<PostSubscriptionOfferEligibilityResponse, IError>(api.get(url));
  },
};

export default itemsApi; 