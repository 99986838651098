import {
  IChatDetails,
  IChatMessageResponse,
  IChatMessageSuccessResponse,
  IRecentChat,
  ISendMessageError,
  IUnlockImageResponse,
  IUnlockMessagesResponse,
  IUnlockCharactersResponse,
  IAudioMessageQuotaResponse,
  IUnlockImageGenResponse,
  IChatMessageSuggestionResponse,
  ICharacterChatProfileResponse
} from '@/models/chat';
import { ICharacterCreationResponse, IDeleteCharacterResponse, IDeleteImageResponse, IImageDataInAlbum } from '@/models/characters';
import { IImageGenerationResponse } from '@/models/image';
import api, { callApi } from './config';
import { IError } from '@/models/common';
import { ICharacter, ICharacterAlbum } from '@/models/characters';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { getSession } from 'next-auth/react';

// Utility function to properly join base URL and path, handling slash inconsistencies
function joinUrls(baseUrl: string, path: string): string {
  if (!baseUrl) return path;
  
  const hasTrailingSlash = baseUrl.endsWith('/');
  const hasLeadingSlash = path.startsWith('/');
  
  if (hasTrailingSlash && hasLeadingSlash) {
    return baseUrl + path.substring(1);
  } else if (!hasTrailingSlash && !hasLeadingSlash) {
    return baseUrl + '/' + path;
  } else {
    return baseUrl + path;
  }
}

// Shared function for setting up streaming requests
async function setupStreamRequest(url: string, payload: any, includeCredentials: boolean = false) {
  // Get the base URL from environment or use the default
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';
  const fullUrl = joinUrls(baseUrl, url);
  
  // Get the authentication token from the session
  const session = await getSession();
  const provider = session?.provider;
  const token = (provider === 'google' || provider === 'credentials') ? session?.idToken : session?.accessToken;
  
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Accept': 'application/x-ndjson'
  };
  
  // Add authentication token if available from session
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
    
    if (provider) {
      headers['Provider'] = provider;
    }
  } else {
    // Fallback to cookie-based authentication
    // The fetch API will automatically include cookies
    console.log('No session token available, relying on cookies for authentication');
  }
  
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
    credentials: includeCredentials ? 'include' : 'same-origin' as RequestCredentials, // Only include cookies if explicitly requested
  };
  
  return { fullUrl, requestOptions };
}

const charactersApi = {
  getActiveChars() {
    const url = '/roleplay/active_characters';
    return callApi<ICharacter[], IError>(api.get(url));
  },
  getChatDetails(characterId: number, lastMessageId?: number): Promise<IChatDetails> {
    let url = `/roleplay/character/${characterId}/chat`;

    if (lastMessageId) {
      url += `?last_message_id=${lastMessageId}`;
    }

    return api.get<never, IChatDetails>(url);
  },
  async sendMessageToChar(character_id: number, user_input: string) {
    const url = `/roleplay/character/${character_id}/message`;
    return callApi<IChatMessageResponse[], ISendMessageError>(api.post(url, { character_id, user_input }));
  },
  async sendMessageToCharStream(character_id: number, user_input: string, platform_id?: number, channel_id?: string) {
    const url = `/roleplay/character/${character_id}/message/stream`;
    const payload = { user_input, platform_id, channel_id };
    
    try {
      // Don't include credentials by default to avoid CORS issues
      const { fullUrl, requestOptions } = await setupStreamRequest(url, payload);
      
      const response = await fetch(fullUrl, requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.error('Error in streaming API call:', error);
      throw error;
    }
  },
  async requestImage(character_id: number, user_input: string) {
    const url = `/roleplay/character/${character_id}/request_image`;
    return callApi<IChatMessageResponse[], ISendMessageError>(api.post(url, { character_id, user_input }));
  },
  async getUserRecentChats() {
    const url = '/roleplay/inbox';
    return callApi<IRecentChat[], any>(api.get(url));
  },
  async resetChat(id: number, user_input?: string, input_type?: string) {
    const url = `/roleplay/character/${id}/reset`;
    return callApi<IChatMessageResponse[], any>(api.post(url, { user_input, input_type }));
  },
  async deleteChat(id: number) {
    const url = `/roleplay/inbox/delete/${id}`;
    return callApi<{}, any>(api.post(url));
  },
  async unlockImage(characterId: number, imageId: number) {
    const url = `/roleplay/character/${characterId}/unlock_image/${imageId}`;
    return callApi<IUnlockImageResponse, IError>(api.post(url));
  },
  async unlockMessages(characterId: number) {
    const url = `/roleplay/unlock_messages?character=${characterId}`;
    return callApi<IUnlockMessagesResponse, IError>(api.post(url));
  },
  async unlockAudioMessages() {
    const url = `/roleplay/unlock_audio_messages`;
    return callApi<IUnlockImageGenResponse, IError>(api.post(url));
  },
  async unlockImageGen() {
    const url = `/image/unlock_image_gen`;
    return callApi<IUnlockMessagesResponse, IError>(api.post(url));
  },
  async unlockCharacters() {
    const url = `/roleplay/unlock_characters`;
    return callApi<IUnlockCharactersResponse, IError>(api.post(url));
  },
  async getAlbums(characterId: number) {
    const url = `/roleplay/character/${characterId}/albums`;
    return callApi<ICharacterAlbum[], IError>(api.get(url));
  },
  async getFanImages(characterId: number, lastImageId: number, limit: number) {
    const url = `/roleplay/character/${characterId}/fan_images`;
    return callApi<IImageDataInAlbum[], IError>(api.get(url, { params: { last_image_id: lastImageId, limit: limit } }));
  },
  async regenerateLastMessage(characterId: number, user_input?: string) {
    const url = `/roleplay/character/${characterId}/regen_last_message`;
    return callApi<IChatMessageSuccessResponse, IError>(api.post(url, { user_input }));
  },
  async regenerateLastMessageStream(character_id: number, user_input?: string) {
    const url = `/roleplay/character/${character_id}/regen_last_message/stream`;
    const payload = { user_input };
    
    try {
      // Don't include credentials by default to avoid CORS issues
      const { fullUrl, requestOptions } = await setupStreamRequest(url, payload);
      
      const response = await fetch(fullUrl, requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.error('Error in streaming API call:', error);
      throw error;
    }
  },
  async continueLastMessage(characterId: number) {
    const url = `/roleplay/character/${characterId}/continue_last_message`;
    return callApi<IChatMessageSuccessResponse, IError>(api.post(url));
  },
  async continueLastMessageStream(character_id: number, platform_id?: number, channel_id?: string) {
    const url = `/roleplay/character/${character_id}/continue_last_message/stream`;
    const payload = { platform_id, channel_id };
    
    try {
      // Don't include credentials by default to avoid CORS issues
      const { fullUrl, requestOptions } = await setupStreamRequest(url, payload);
      
      const response = await fetch(fullUrl, requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.error('Error in streaming API call:', error);
      throw error;
    }
  },
  async suggestNextMessage(characterId: number) {
    const url = `/roleplay/character/${characterId}/suggest_next_message`;
    return callApi<IChatMessageSuggestionResponse, IError>(api.post(url));
  },
  async setCharacterActiveChatProfile(characterId: number, chatProfileId: number) {
    const url = `/roleplay/character/${characterId}/set_active_chat_profile/${chatProfileId}`;
    return callApi<ICharacterChatProfileResponse, IError>(api.post(url));
  },
  async generateImageForLastMessage(characterId: number) {
    const url = `/roleplay/character/${characterId}/generate_image_for_last_message`;
    return callApi<IChatMessageSuccessResponse, IError>(api.post(url));
  },

  getTemplateImages(count: number, style: string) {
    const url = `/roleplay/template_images?count=${count}&style=${style}`;
    return callApi<{ images: { url: string; key: string; style: string, image_request_id: string }[] }, IError>(api.get(url));
  },

  async createCharacter(choices: Record<string, any>, experiment_variant?: string | null) {
    const url = '/roleplay/create_character';
    return callApi<ICharacterCreationResponse, IError>(api.post(url, { ...choices, experiment_variant }));
  },

  async renameCharacter(characterId: number, name: string) {
    const url = `/roleplay/character/${characterId}/rename`;
    return callApi<ICharacter, IError>(api.post(url, { name }));
  },

  async deleteCharacter(characterId: number) {
    const url = `/roleplay/character/${characterId}/delete`;
    return callApi<IDeleteCharacterResponse, IError>(api.post(url));
  },

  async deleteImages(characterId: number, imageIds: number[]) {
    const url = `/roleplay/character/${characterId}/delete_images`;
    return callApi<IDeleteImageResponse, IError>(api.post(url, imageIds));
  },

  async generateNewCharacterImage(choices: Record<string, any>) {
    const url = '/image/generate_new_character_image';
    return callApi<IImageGenerationResponse, IError>(api.post(url, { ...choices }));
  },

  async generateUserImage(characterId: number, userPrompt: string) {
    const url = '/image/generate_user_image';
    return callApi<IImageGenerationResponse, IError>(api.post(url, { character_id: characterId, user_prompt: userPrompt }));
  },

  async pollImageGeneration(image_request_id: number) {
    const url = `/image/poll_generation/${image_request_id}`;
    return callApi<IImageGenerationResponse, IError>(api.post(url));
  },

  // DEV method for burning your messages quota
  async burn() {
    const url = '/roleplay/burn_messages';
    alert('Burning your quota. You will be informed when ready.');
    const res = await callApi<IUnlockMessagesResponse, IError>(api.get(url));

    if (!(res instanceof AxiosError)) {
      alert('Your messages qouta has been reset');
    }
  },
  async generateAudio(messageId: number, characterId: number, messageText: string): Promise<ArrayBuffer | IAudioMessageQuotaResponse> {
    const url = `/roleplay/generate_audio`;
    const payload = {
      message_id: messageId,
      character_id: characterId,
      message_text: messageText
    };

    try {
      const res = await api.post<ArrayBuffer>(url, payload, { 
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json, application/octet-stream' }
      });

      // Check the size of res.data
      // If the size is suspiciously small, it might be a JSON response
      // This part is a bit hacky, wan't able to find a cleaner way to detect the response type
      // given some api custom middleware in config.ts
      const arrayBufferData = res instanceof ArrayBuffer ? res : res.data;
      if (arrayBufferData instanceof ArrayBuffer) {
        const dataSize = arrayBufferData.byteLength;
        if (dataSize < 1024) {
          const text = new TextDecoder().decode(arrayBufferData);
          try {
            const jsonResponse = JSON.parse(text);
            if ('audio_locked' in jsonResponse) {
              return jsonResponse as IAudioMessageQuotaResponse;
            }
          } catch (e) {
            // Not JSON, continue treating as ArrayBuffer
          }      
        }
        return arrayBufferData;
      } 
      
      return res as unknown as ArrayBuffer;

    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  },
  async getAudioSample(characterId: number): Promise<ArrayBuffer> {
    const url = `/roleplay/get_audio_sample`;
    const payload = {
      character_id: characterId,
    };

    try {
      const res = await api.post<ArrayBuffer>(url, payload, { 
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json, application/octet-stream' }
      });

      return res instanceof ArrayBuffer ? res : res.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  },  
  async getVoiceSampleUrl(voiceId: string, personality: string): Promise<string> {
    // Check if this is a male voice (IDs 36-40 are male voices based on creationData.ts)
    const isMaleVoice = ["36", "37", "38", "39", "40"].includes(voiceId);
    
    // Prepend "male_" to personality if it's a male voice
    const adjustedPersonality = isMaleVoice ? `male_${personality}` : personality;
    
    const url = `/roleplay/voice_samples?voice_id=${voiceId}&personality=${adjustedPersonality}`;
    try {
      console.log('Fetching voice sample URL:', url);
      const response = await api.get<{ url: string }>(url);
      
      console.log('Full API response:', response);
      console.log('Response type:', typeof response);
      console.log('Response keys:', Object.keys(response));
      
      if (!response || typeof response !== 'object') {
        throw new Error('Invalid API response');
      }
      
      if (!('url' in response)) {
        throw new Error('Response is missing "url" property');
      }
      
      if (typeof response.url !== 'string') {
        throw new Error(`Unexpected url type: ${typeof response.url}`);
      }
      
      console.log('Successfully retrieved voice sample URL:', response.url);
      return response.url;
    } catch (error) {
      console.error('Error in getVoiceSampleUrl:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      throw error;
    }
  },

  // Add a static method for SSG
  getActiveCharsStatic: async () => {
    // Normalize the base URL to handle trailing slashes
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, '');
    const response = await fetch(`${baseUrl}/roleplay/active_characters`);
    return response.json();
  },

  getCharactersByCards(characterCards: string[]) {
    const url = '/roleplay/get_characters_by_cards';
    return callApi<ICharacter[], IError>(api.post(url, characterCards));
  },

  // Get a single character by ID
  getCharacterById(characterId: number) {
    const url = `/roleplay/character/${characterId}`;
    return callApi<ICharacter, IError>(api.post(url));
  },
  
  // Resolve unique_code to numeric ID
  resolveUniqueCode(uniqueCode: string) {
    const url = `/roleplay/character/identifiers/${uniqueCode}`;
    return callApi<{ id: number }, IError>(api.get(url));
  },
  
  // Static version of resolveUniqueCode for SSR
  resolveUniqueCodeStatic: async (uniqueCode: string, authToken?: string, provider?: string) => {
    try {
      // Normalize the base URL to handle trailing slashes
      const baseUrl = process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, '');
      
      if (!baseUrl) {
        console.error('NEXT_PUBLIC_BASE_URL environment variable is not set');
        return null;
      }
      
      const url = `${baseUrl}/roleplay/character/identifiers/${uniqueCode}`;
      console.log(`Resolving unique code from: ${url}`);
      
      // Prepare headers with authentication if available
      const headers: Record<string, string> = {
        'Content-Type': 'application/json'
      };
      
      // Add authentication token if available
      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
        
        // Add provider if available
        if (provider) {
          headers['Provider'] = provider;
        }
      }
      
      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      
      // Handle potential errors
      if (!response.ok) {
        if (response.status === 404) {
          console.log(`Unique code ${uniqueCode} not found`);
          return null; // Unique code not found
        }
        
        throw new Error(`Failed to resolve unique code: ${response.status} - ${response.statusText}`);
      }
      
      const data = await response.json();
      
      // Validate that we received a proper response with numeric ID
      if (!data || typeof data !== 'object' || !('id' in data) || typeof data.id !== 'number') {
        console.error('Invalid response received from unique code resolution:', data);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in resolveUniqueCodeStatic:', error);
      return null; // Return null instead of throwing to prevent SSR failures
    }
  },

  // Static version for SSG/SSR
  getCharacterByIdStatic: async (characterId: number, authToken?: string, provider?: string) => {
    try {
      // Normalize the base URL to handle trailing slashes
      const baseUrl = process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, '');
      
      if (!baseUrl) {
        console.error('NEXT_PUBLIC_BASE_URL environment variable is not set');
        return null;
      }
      
      const url = `${baseUrl}/roleplay/character/${characterId}`;
      console.log(`Fetching character data from: ${url}`);
      
      // Prepare headers with authentication if available
      const headers: Record<string, string> = {
        'Content-Type': 'application/json'
      };
      
      // Add authentication token if available
      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
        console.log('Including authentication token in character request');
        
        // Add provider if available
        if (provider) {
          headers['Provider'] = provider;
        }
      }
      
      const response = await fetch(url, {
        method: 'POST',
        headers
      });
      
      // Handle potential errors
      if (!response.ok) {
        if (response.status === 404) {
          console.log(`Character with ID ${characterId} not found`);
          return null; // Character not found
        }
        
        // Try to get more details from the error response
        let errorDetails = '';
        try {
          const errorJson = await response.json();
          errorDetails = JSON.stringify(errorJson);
        } catch (e) {
          // If we can't parse the JSON, just use the status text
          errorDetails = response.statusText;
        }
        
        throw new Error(`Failed to fetch character: ${response.status} - ${errorDetails}`);
      }
      
      const data = await response.json();
      
      // Validate that we received a proper character object
      if (!data || typeof data !== 'object' || !data.id) {
        console.error('Invalid character data received:', data);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in getCharacterByIdStatic:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      return null; // Return null instead of throwing to prevent SSR failures
    }
  },
};

export default charactersApi;
